import { ShipInfo } from './views/ShipInfo';
import ReportInfo from './views/ReportInfo';
import RequestInfo from './views/RequestInfo';
import { Introduction } from './views/Introduction';
import FuelUsage from './views/FuelUsage';
import EngineInfo from './views/EngineInfo';

export default [
  { name: 'Introductie', component: <Introduction /> },
  { name: 'Schip', component: <ShipInfo /> },
  { name: 'Motorinformatie', component: <EngineInfo /> },
  { name: 'Brandstof', component: <FuelUsage /> },
  { name: 'Rapportage', component: <ReportInfo /> },
  { name: 'Overzicht', component: <RequestInfo /> }
];
