import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';
import useStore from '../../../../store/useStore';

const FuelTypeSelect = ({ onSelect, selected, className, multiple }) => {
  const { getFuelTypes, loadingFuelTypes: loading, fuelTypes } = useStore((state) => state);
  const { t } = useTranslation();

  useEffect(() => {
    getFuelTypes();
  }, [getFuelTypes]);

  if (loading) {
    return (
      <TextField
        fullWidth
        className={className}
        label={t('fuel_type.type')}
        defaultValue={t('fuel_type.loading_types')}
        disabled
      />
    );
  }

  if (fuelTypes.length === 0) {
    return (
      <TextField
        fullWidth
        className={className}
        name="fuel_type"
        id="fuel_types"
        label={t('fuel_type.no_types')}
        defaultValue={t('fuel_type.no_types')}
        disabled
      />
    );
  }

  return (
    <TextField
      className={className}
      fullWidth
      select
      name="fueltypes"
      id="fueltypes"
      label={t('fuel_type.type')}
      defaultValue={t('fuel_type.type')}
      SelectProps={{
        multiple,
        value: multiple ? selected.map((s) => s.id) : selected?.id || '',
        onChange: (e) => {
          if (multiple) return onSelect(fuelTypes.filter((et) => e.target.value.includes(et.id)));
          return onSelect(fuelTypes.find((et) => e.target.value === et.id));
        }
      }}
    >
      {fuelTypes?.map((option) => (
        <MenuItem className="py-10" key={option.id} value={option.id}>
          {option.description}
        </MenuItem>
      ))}
    </TextField>
  );
};

FuelTypeSelect.defaultProps = {
  className: 'mw-100',
  multiple: false
};

FuelTypeSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  multiple: PropTypes.bool
};

export default FuelTypeSelect;
