import PropTypes from 'prop-types';
import { Card, CardHeader, Grid, Stack } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import ShipForm from './ShipForm';

ShipDetails.propTypes = {
  ship: PropTypes.object,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool
};

export default function ShipDetails({ ship, loading, isEdit }) {
  const { t } = useTranslation();
  if (loading || (!ship && isEdit)) {
    return (
      <Card>
        <CardHeader title={t('ship.ship')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack spacing={3}>
          <Card sx={{ py: 3 }}>
            <CardHeader title={t('ship.details')} />
            <ShipForm ship={ship} />
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
}
