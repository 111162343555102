import i18n from 'i18next';
import getAxiosConnection from '../utils/axios';

const initialState = {
  measurements: [],
  expiredMeasurements: [],
  creatingMeasurements: false,
  retrievingMeasurements: false,
  updatingMeasurements: false,
  deletingMeasurement: false
};

const measurementSlice = (set, get) => ({
  ...initialState,
  createMeasurement: async (data) => {
    set({ creatingMeasurements: true });
    const dataArray = new FormData();
    const keys = Object.keys(data);
    const { language } = i18n;
    keys.forEach((key) => {
      if (key !== 'proof') {
        dataArray.append(key, data[key]);
      }
    });
    dataArray.append('file', data.proof);
    return getAxiosConnection('multipart/form-data')
      .post(`engines/measurement?language=${language}`, dataArray)
      .then((res) => {
        const newMeasurement = res.data;
        const { measurements } = get();
        measurements.push(newMeasurement);
        set({ measurements });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ creatingMeasurements: false });
      });
  },
  updateMeasurement: async (data, id) => {
    set({ updatingMeasurements: true });
    const dataArray = new FormData();
    const keys = Object.keys(data);
    const { language } = i18n;
    keys.forEach((key) => {
      if (key !== 'proof') {
        dataArray.append(key, data[key]);
      }
    });
    dataArray.append('file', data.proof);
    dataArray.append('id', id);
    return getAxiosConnection('multipart/form-data')
      .post(`engines/measurement/${id}?language=${language}`, dataArray)
      .then((res) => {
        const currentMeasurements = get().measurements;
        let measurements = [];
        measurements = currentMeasurements.map((measurement) =>
          measurement.id === res.data.id ? res.data : measurement
        );
        set({ measurements });
        return res.data;
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ updatingMeasurements: false });
      });
  },
  deleteMeasurement: async (id) => {
    set({ deletingMeasurement: true });
    getAxiosConnection()
      .delete(`measurement/${id}`)
      .then(() => {
        const oldMeasurements = get().measurements;
        const measurements = oldMeasurements.filter((measurement) => measurement.id !== id);
        set({ measurements });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ deletingMeasurement: false });
      });
  },
  getExpiredMeasurements: async () => {
    set({ retrievingMeasurements: true });
    getAxiosConnection()
      .get(`/user/expiredMeasurements`)
      .then((res) => {
        const currentMeasurements = get().expiredMeasurements;
        let expiredMeasurements = [];
        if (Object.values(res.data).length > 0) {
          expiredMeasurements = res.data.map(
            (obj) => currentMeasurements.find((o) => o.id === obj.id) || obj
          );
        }
        set({ expiredMeasurements });
        return expiredMeasurements;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ retrievingMeasurements: false });
      });
  }
});

export default measurementSlice;
