import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import Loader from 'react-loader-spinner';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper
} from '@material-ui/core';
import { filter } from 'lodash';
import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { ShipListHead, ShipListToolbar, ShipMoreMenu } from '../components/_dashboard/ship/list';
import useStore from '../store/useStore';
import { PATH_CLIENT } from '../routes/paths';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (array.size === 0) return array;
  array.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) return order;
    return a - b;
  });
  if (query) {
    return filter(array, (_ship) => _ship.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return array;
}

export default function Ship() {
  const { t } = useTranslation();
  const { ships, getShips } = useStore((state) => state);
  const { loadingShips, deletingShip } = useStore((state) => state);

  useEffect(() => {
    getShips();
  }, [getShips]);

  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('title');
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);

  const user = useStore((state) => state.user);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user?.roles?.includes('ROLE_ADMIN')) {
      setIsAdmin(true);
    } else if (user?.organization.roles.includes('ROLE_ADMIN')) {
      setIsAdmin(true);
    }
  }, [user]);

  let TABLE_HEAD = [];
  if (isAdmin) {
    TABLE_HEAD = [
      { id: 'title', label: t('ship_name'), alignRight: false },
      { id: 'eni', label: 'ENI', alignRight: false },
      { id: 'type', label: t('ship.type'), alignRight: false },
      { id: 'engines', label: t('no_of_engines'), alignRight: false },
      { id: 'creator', label: t('creator'), alignRight: false },
      { id: '' }
    ];
  } else {
    TABLE_HEAD = [
      { id: 'title', label: t('ship_name'), alignRight: false },
      { id: 'eni', label: 'ENI', alignRight: false },
      { id: 'type', label: t('ship.type'), alignRight: false },
      { id: 'engines', label: t('no_of_engines'), alignRight: false },
      { id: '' }
    ];
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredShips = applySortFilter(ships, getComparator(order, orderBy), filterName);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ships.length) : 0;

  const isShipNotFound = filteredShips.length === 0 && ships.length > 0;
  const isShipsEmpty = ships.length === 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page title={t('my_ships')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('my_ships')}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: t('my_ships') }]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to="create"
              startIcon={<Icon icon={plusFill} />}
            >
              {t('new_ship')}
            </Button>
          }
        />
        <Card>
          <ShipListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ShipListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredShips
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, createdBy, title, eni, engines, type } = row;

                      const link = `${id}`;
                      const isItemSelected = selected.indexOf(title) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, title)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography to={link} component={RouterLink} variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{eni}</TableCell>
                          <TableCell align="left">{type}</TableCell>
                          <TableCell align="left">{engines}</TableCell>
                          {isAdmin && <TableCell align="left">{createdBy.name}</TableCell>}

                          <TableCell align="right">
                            <ShipMoreMenu ship={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isShipNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {(loadingShips || deletingShip) && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper>
                          <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {isShipsEmpty && !loadingShips && !deletingShip && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper>
                          <Typography gutterBottom align="center" variant="subtitle1">
                            {t('no_ships')}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={ships.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
