import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';
import useStore from '../../../../store/useStore';

const EngineTypeSelect = ({ onSelect, selected, className, engine }) => {
  const { getEngineTypes, loadingEngineTypes: loading, engineTypes } = useStore((state) => state);
  const { t } = useTranslation();
  const [compatibleEngineTypes, setCompatibleEngineTypes] = useState([]);
  useEffect(() => {
    getEngineTypes();
  }, [getEngineTypes]);

  useEffect(() => {
    if (engine) {
      const selectedFuelType = engine.fuelType;
      const compatibleList = [];
      engineTypes.forEach((engineType) => {
        if (
          engineType.fuelTypes.findIndex((fuelType) => fuelType.id === selectedFuelType.id) > -1
        ) {
          compatibleList.push(engineType);
        }
        setCompatibleEngineTypes(compatibleList);
      });
    } else {
      setCompatibleEngineTypes(engineTypes);
    }
  }, [engine, engineTypes]);

  if (loading) {
    return (
      <TextField
        fullWidth
        className={className}
        label={t('engine.type')}
        defaultValue={t('engine.loading_types')}
        disabled
      />
    );
  }

  if (engineTypes.length === 0) {
    return (
      <TextField
        fullWidth
        className={className}
        name="editions"
        id="editions"
        label={t('engine.type')}
        defaultValue={t('no_engine_types')}
        disabled
      />
    );
  }

  return (
    <TextField
      className={className}
      fullWidth
      select
      name="engines"
      id="engines"
      label={t('engine.type')}
      defaultValue={t('engine.type')}
      SelectProps={{
        multiple: false,
        value: selected?.id || '',
        onChange: (e) => onSelect(engineTypes.find((et) => e.target.value === et.id))
      }}
    >
      {compatibleEngineTypes.map((option) => (
        <MenuItem className="py-10" key={option.id} value={option.id}>
          {t(option.code)}
        </MenuItem>
      ))}
    </TextField>
  );
};

EngineTypeSelect.defaultProps = {
  className: 'mw-100'
};

EngineTypeSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object,
  className: PropTypes.string,
  engine: PropTypes.object
};

export default EngineTypeSelect;
