import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import peopleOutline from '@iconify/icons-eva/people-outline';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import closeFill from '@iconify/icons-eva/close-fill';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack5';
import { fStringCapitalizeFirstLetter } from '../../../../utils/formatString';
import useStore from '../../../../store/useStore';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

function AssignedEditorsDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Icon icon={closeFill} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

AssignedEditorsDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function AssignedEditorsDialog({ ship }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const { addShipEditor, getShipEditors, getShipOrganizationMembers, removeShipEditor } = useStore(
    (state) => state
  );
  const [editors, setEditors] = useState([]);
  const [members, setMembers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [disabledList, setDisabledList] = useState([]);

  useEffect(() => {
    if (ship) {
      getShipEditors(ship.id).then((res) => setEditors(res));
      getShipOrganizationMembers(ship.id).then((res) => setMembers(res));
    }
  }, [getShipEditors, getShipOrganizationMembers, ship]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (userId) => {
    if (editors.some((e) => e.id === userId)) {
      if (userId === ship.createdBy.id) {
        enqueueSnackbar(t('cannot_remove_owner'), {
          variant: 'warning'
        });
      } else {
        setDisabledList([...disabledList, userId]);
        removeShipEditor(ship.id, userId).then((res) => {
          setEditors(res);
          setDisabledList(disabledList.filter((a) => a !== userId));
        });
      }
    } else {
      setDisabledList([...disabledList, userId]);
      addShipEditor(ship.id, userId).then((res) => {
        setEditors(res);
        setDisabledList(disabledList.filter((a) => a !== userId));
      });
    }
  };

  return (
    <div>
      <MenuItem sx={{ color: 'text.secondary' }} onClick={handleClickOpen}>
        <ListItemIcon>
          <Icon icon={peopleOutline} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary={t('assign')} primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <AssignedEditorsDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('manage_rights')}
        </AssignedEditorsDialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{fStringCapitalizeFirstLetter(t('name'))}</TableCell>
                  <TableCell>{fStringCapitalizeFirstLetter(t('email'))}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell>
                      <Checkbox
                        disabled={disabledList.includes(member.id)}
                        checked={editors.some((e) => e.id === member.id)}
                        onClick={() => handleClick(member.id)}
                      />{' '}
                    </TableCell>
                    <TableCell>
                      {member.firstName} {member.lastName}
                    </TableCell>
                    <TableCell>{member.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('close')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

AssignedEditorsDialog.propTypes = {
  ship: PropTypes.object
};
