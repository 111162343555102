export function updateArrayWithObject(array, objectToUpdate, key = 'id') {
  const isObjectExists = array.some((obj) => obj[key] === objectToUpdate[key]);

  if (isObjectExists) {
    return array.map((obj) => {
      if (obj.id === objectToUpdate.id) {
        return objectToUpdate;
      }
      return obj;
    });
  }
  return [...array, objectToUpdate];
}

export function removeObjectFromArray(array, objectToRemove, key = 'id') {
  return array.filter((obj) => obj[key] !== objectToRemove[key]);
}
