import { Button, Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';

export const List = styled('ul')(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  marginLeft: '1.5rem'
}));

// eslint-disable-next-line react/prop-types
export const Introduction = ({ jumpToStep }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3} padding={3}>
      <Grid item xs={12}>
        <Typography variant="body2" align="left">
          <b>{t('introduction.read_carefully')}.</b>
          <br />
          <br />
          <b>{t('introduction.steps')}.</b>
        </Typography>
        <List>
          <li>{t('introduction.step_1')}</li>
          <li>{t('introduction.step_2')}</li>
          <li>{t('introduction.required_documentation')}</li>
          <List>
            <li>{t('introduction.documentation_1')}</li>
            <li>{t('introduction.documentation_2')}</li>
            <li>{t('introduction.documentation_3')}</li>
            <li>{t('introduction.documentation_4')}</li>
            <li>{t('introduction.documentation_5')}</li>
            <li>{t('introduction.documentation_6')}</li>
          </List>
          <li>{t('introduction.report')}</li>
          <List>
            <li>{t('introduction.report_1')}</li>
            <li>{t('introduction.report_2')}</li>
            <li>{t('introduction.report_3')}</li>
          </List>
        </List>
        <br />
        <Typography variant="body2" align="left">
          {t('introduction.extra')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" variant="contained" onClick={() => jumpToStep(1)}>
            {t('next')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
