import { Icon } from '@iconify/react';
import { useState } from 'react';
import i18n from 'i18next';
import Loader from 'react-loader-spinner';
import cloudDownloadOutline from '@iconify/icons-eva/cloud-download-outline';
import { useSnackbar } from 'notistack5';
import { Link } from '@material-ui/core';
import useStore from '../store/useStore';
import { DownloadCell } from '../layouts/styles';

export default function useDownloadList() {
  const [downloadList, setDownloadList] = useState([]);
  const getFile = useStore((state) => state.getFile);
  const { enqueueSnackbar } = useSnackbar();

  function downloadFile(file) {
    setDownloadList([...downloadList, file]);
    getFile(file)
      .catch(() => {
        enqueueSnackbar(i18n.t('something_wrong'), { variant: 'error' });
      })
      .finally(() => {
        const downloads = downloadList.filter((element) => element !== file);
        setDownloadList(downloads);
      });
  }

  function downloadIcon(fileName) {
    if (downloadList.includes(fileName))
      return <Loader key={fileName} type="ThreeDots" color="#00BFFF" height={15} width={15} />;

    return (
      <Icon
        key={fileName}
        sx={{ padding: '2rem' }}
        onClick={() => downloadFile(fileName)}
        icon={cloudDownloadOutline}
      />
    );
  }

  function downloadLink(fileName, title) {
    return (
      <Link underline="hover" color="inherit" href="#" onClick={() => downloadFile(fileName)}>
        {title}
      </Link>
    );
  }

  function downloadCell(fileName) {
    return (
      <DownloadCell onClick={() => downloadFile(fileName)}>
        {downloadList.includes(fileName) ? (
          <Loader type="ThreeDots" color="#00BFFF" height={15} width={15} />
        ) : (
          <Icon icon={cloudDownloadOutline} />
        )}
      </DownloadCell>
    );
  }

  return {
    downloadCell,
    downloadFile,
    downloadLink,
    downloadButton: downloadIcon
  };
}
