import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';

// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Paper
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import cloudDownloadOutline from '@iconify/icons-eva/cloud-download-outline';
import useStore from '../../../store/useStore';
import Scrollbar from '../../Scrollbar';
import LabelListHead from './LabelListHead';
import { fDate } from '../../../utils/formatTime';
// components
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  if (array.size === 0) return array;
  array.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) return order;
    return a - b;
  });

  return array;
}

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4)
}));

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  textAlign: 'center'
}));

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary
}));

export default function LabelList() {
  const { t } = useTranslation();
  const loading = useStore((state) => state.loadingLabels);
  const getLabels = useStore((state) => state.getLabels);
  const labels = useStore((state) => state.labels);

  useEffect(() => {
    getLabels();
  }, [getLabels]);

  const [orderBy, setOrderBy] = useState('title');
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { downloadLabel, downloadingLabel } = useStore((state) => state);

  const TABLE_HEAD = [
    { id: 'title', label: t('ship.title'), alignRight: false },
    { id: 'label', label: t('label.label'), alignRight: false },
    { id: 'valid_from', label: t('label.valid_from'), alignRight: false },
    { id: '' }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - labels.length) : 0;
  const isLabelEmpty = labels.length === 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filteredLabels = applySortFilter(labels, getComparator(order, orderBy));

  return (
    <DetailCard sx={{ p: 3 }}>
      <CardHeader>
        <LabelStyle>{t('labels')}</LabelStyle>
      </CardHeader>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <LabelListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            {!loading && (
              <TableBody>
                {filteredLabels
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      id,
                      ship,
                      climateLabel,
                      airQualityLabel,
                      dateOfGeneration,
                      documentNumber
                    } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell align="left">{ship?.title}</TableCell>
                        <TableCell align="left">
                          {climateLabel} {airQualityLabel}
                        </TableCell>
                        <TableCell align="left">{fDate(dateOfGeneration)}</TableCell>
                        <TableCell>
                          {downloadingLabel ? (
                            <Loader type="ThreeDots" color="#00BFFF" height={20} width={20} />
                          ) : (
                            <Icon
                              onClick={() => downloadLabel(id, documentNumber)}
                              icon={cloudDownloadOutline}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
            {loading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper>
                      <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {isLabelEmpty && !loading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper>
                      <Typography gutterBottom align="center" variant="subtitle1">
                        {t('label.no_labels')}
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={labels.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DetailCard>
  );
}
