import getAxiosConnection from '../utils/axios';
import { updateArrayWithObject, removeObjectFromArray } from '../utils/updateArrayOfObjects';

const initialState = {
  fuelConsumptions: [],
  ships: [],
  shipTypes: [],
  loadingShip: false,
  loadingShips: false,
  savingShip: false,
  savingFuelConsumption: false,
  searchingShip: false,
  loadingShipTypes: false,
  loadingFuelTypes: false,
  loadingFuelConsumption: false,
  deletingShip: false,
  currentShip: null
};

const shipSlice = (set, get) => ({
  ...initialState,
  getShips: async () => {
    if (get().ships.length === 0) set({ loadingShips: true });
    return getAxiosConnection()
      .get('ships')
      .then((res) => {
        set({ ships: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingShips: false });
      });
  },
  getShip: async (id) => {
    if (get().currentShip && Number(get().currentShip.id) === Number(id)) return get().currentShip;
    set({ loadingShip: true });
    return getAxiosConnection()
      .get(`ship/${id}`)
      .then((res) => {
        set({ currentShip: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingShip: false });
      });
  },
  createShip: async (ship) => {
    set({ savingShip: true });
    return getAxiosConnection()
      .post('ships', { ...ship })
      .then((res) => {
        get().getShips();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingShip: false });
      });
  },
  updateShip: async (ship) => {
    set({ savingShip: true });
    return getAxiosConnection()
      .post(`ship/${ship.id}`, { ...ship })
      .then((res) => {
        get().getShips();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingShip: false });
      });
  },
  getShipTypes: async () => {
    set({ loadingShipTypes: true });
    return getAxiosConnection()
      .get('/ship/types/get')
      .then((res) => {
        set({ shipTypes: res.data });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingShipTypes: false });
      });
  },
  getShipFuelTypes: async (id) => {
    set({ loadingFuelTypes: true });
    return getAxiosConnection()
      .get(`ship/${id}/fuelTypes`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ loadingFuelTypes: false });
      });
  },
  getShipFuelConsumption: async (id, year) => {
    set({ loadingFuelConsumption: true });
    return getAxiosConnection()
      .get(`ship/${id}/fuelConsumption?year=${year}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ loadingFuelConsumption: false });
      });
  },
  searchShipByName: async (name) => {
    set({ searchingShip: true });
    return getAxiosConnection()
      .get(`ship/search/name/?name=${name}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ searchingShip: false });
      });
  },
  searchShipByENI: async (eni) => {
    set({ searchingShip: true });
    return getAxiosConnection()
      .get(`ship/search/eni/?eni=${eni}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ searchingShip: false });
      });
  },
  removeCurrentShip: () => {
    set({ currentShip: null });
  },
  setCurrentShip: (currentShip) => {
    set({ currentShip });
  },
  refreshShip: () => {
    set({ ...initialState }, true);
  },
  deleteShip: (id) => {
    set({ deletingShip: true });
    getAxiosConnection()
      .delete(`ship/${id}`)
      .then(() => {
        const ships = get().ships.filter((ship) => ship.id !== id);
        set({ ships });
        return id;
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ deletingShip: false });
      });
  },
  addFuelConsumption: async (fc, shipId) => {
    set({ savingFuelConsumption: true });
    return getAxiosConnection()
      .post(`ship/${shipId}/fuelConsumption`, { ...fc })
      .then((res) => {
        const currentFuelConsumptions = get().fuelConsumptions;
        const fuelConsumptions = updateArrayWithObject(currentFuelConsumptions, res.data);

        set({ fuelConsumptions });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingFuelConsumption: false });
      });
  },
  getFuelConsumption: async (shipId, startDate, endDate) => {
    set({ loadingFuelConsumption: true });
    return getAxiosConnection()
      .post(`ship/${shipId}/fuelConsumptions`, { from: startDate, until: endDate })
      .then((res) => {
        set({ fuelConsumptions: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingFuelConsumption: false });
      });
  },
  removeFuelConsumption: async (fuelConsumptionId) => {
    set({ loadingFuelConsumption: true });
    return getAxiosConnection()
      .delete(`fuelConsumption/${fuelConsumptionId}`)
      .then(() => {
        const currentFuelConsumptions = get().fuelConsumptions;
        const fuelConsumptions = removeObjectFromArray(currentFuelConsumptions, {
          id: fuelConsumptionId
        });

        set({ fuelConsumptions });
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingFuelConsumption: false });
      });
  },
  getShipEditors: async (shipId) =>
    getAxiosConnection()
      .get(`ship/${shipId}/editors`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      }),
  getShipActions: async (shipId) =>
    getAxiosConnection()
      .get(`ship/${shipId}/history`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
});

export default shipSlice;
