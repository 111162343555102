import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Box, Grid, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack5';
import useStore from '../../../../../store/useStore';
import { DetailCard } from '../../../../../layouts/styles';

EmissionLimitsForm.propTypes = {
  engine: PropTypes.object.isRequired,
  submitCallback: PropTypes.func
};

export default function EmissionLimitsForm({ engine, submitCallback }) {
  const { t } = useTranslation();
  const { createEmissionLimit, updateEmissionLimit } = useStore((state) => state);
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: engine.emissionLimits?.id || 0.0,
      SCE3: engine.emissionLimits?.sCE3 || 0.0,
      co2: engine.emissionLimits?.co2 || 0.0,
      nox: engine.emissionLimits?.nox || 0.0,
      pm: engine.emissionLimits?.pm || 0.0,
      hc: engine.emissionLimits?.hc || 0.0,
      pn: engine.emissionLimits?.pn || 0.0,
      a: engine.emissionLimits?.a || 0.0,
      engine: engine.id
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        if (values.id)
          updateEmissionLimit(values).then((res) => {
            enqueueSnackbar(t('saved'), {
              variant: 'success'
            });
            if (submitCallback) {
              submitCallback(res);
            }
          });
        else
          createEmissionLimit(values, engine).then((res) => {
            enqueueSnackbar(t('saved'), {
              variant: 'success'
            });
            if (submitCallback) {
              submitCallback(res);
            }
          });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    if (engine) {
      resetForm();
    }
  }, [engine, resetForm]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <DetailCard sx={{ p: 3 }}>
              <Stack spacing={2} paddingBottom="1rem">
                <TextField
                  fullWidth
                  label={t('engine.hc')}
                  {...getFieldProps('hc')}
                  error={Boolean(touched.hc && errors.hc)}
                  helperText={touched.hc && errors.hc}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t('engine.sc')}
                  {...getFieldProps('SCE3')}
                  error={Boolean(touched.SCE3 && errors.SCE3)}
                  helperText={touched.SCE3 && errors.SCE3}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t('engine.nox')}
                  {...getFieldProps('nox')}
                  error={Boolean(touched.nox && errors.nox)}
                  helperText={touched.nox && errors.nox}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t('engine.pm')}
                  {...getFieldProps('pm')}
                  error={Boolean(touched.pm && errors.pm)}
                  helperText={touched.pm && errors.pm}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t('engine.pn')}
                  {...getFieldProps('pn')}
                  error={Boolean(touched.pn && errors.pn)}
                  helperText={touched.pn && errors.pn}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t('engine.a')}
                  {...getFieldProps('a')}
                  error={Boolean(touched.a && errors.a)}
                  helperText={touched.a && errors.a}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
              </Stack>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  disabled={_.isEmpty(touched)}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t('save')}
                </LoadingButton>
              </Box>
            </DetailCard>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
