import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PATH_CLIENT } from '../routes/paths';
import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { fStringCapitalizeFirstLetter } from '../utils/formatString';
import ApiKeysList from '../components/_dashboard/apiKeys/ApiKeysList';

export default function ApiSettings() {
  const { t } = useTranslation();
  const title = fStringCapitalizeFirstLetter(t('api_settings'));

  return (
    <Page title={title}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={title}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: title }]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <ApiKeysList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
