import { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import useStore from '../../../../store/useStore';
import ReportForm from '../../ship/report/ReportForm';

ReportInfo.propTypes = {
  jumpToStep: PropTypes.func
};

export default function ReportInfo({ jumpToStep }) {
  const { t } = useTranslation();
  const { wizardShip } = useStore((state) => state);
  const [report, setReport] = useState(null);
  const { getReportByYear, loadingReportByYear } = useStore((state) => state);
  const year = new Date().getFullYear() - 1;

  useEffect(() => {
    getReportByYear(wizardShip.id, year).then((res) => {
      if (res) setReport(res);
    });
  }, [wizardShip, getReportByYear, year]);

  const onReportCreated = (report) => {
    setReport(report);
    jumpToStep(5);
  };

  if (loadingReportByYear) {
    return (
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          <Typography variant="body2">{t('report.loading_report')}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} padding={3}>
      <Grid item xs={12}>
        <Typography variant="h4">{report === null ? t('report.new') : t('report.edit')}</Typography>
        <ReportForm
          showOnlyEssential={report !== null}
          submitCallback={(r) => onReportCreated(r)}
          onCancel={() => jumpToStep(3)}
          ship={wizardShip}
          cancelButtonLabel="previous"
          submitButtonLabel="next"
          report={report}
        />
      </Grid>
    </Grid>
  );
}
