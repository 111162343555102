import { useTranslation } from 'react-i18next';
import { deDE, enUS, nlNL } from '@material-ui/core/locale';
import useStore from '../store/useStore';

const LANGS = [
  {
    value: 'en-US',
    label: 'English',
    systemValue: enUS,
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 'nl-NL',
    label: 'Nederlands',
    systemValue: nlNL,
    icon: '/static/icons/ic_flag_nl.svg'
  },
  {
    value: 'de-DE',
    label: 'Deutsch',
    systemValue: deDE,
    icon: '/static/icons/ic_flag_de.svg'
  }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { user, createUser } = useStore((state) => state);

  const foundLanguage = LANGS.find((_lang) => _lang.value === user?.language);
  const currentLang =
    foundLanguage || LANGS.find((_lang) => _lang.value === i18n.language) || LANGS[1];

  const handleChangeLanguage = (newlang) => {
    if (Object.keys(user).length > 0) createUser({ ...user, language: newlang });
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  };
}
