import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Box, Button, Grid, Stack, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store/useStore';

EngineSubTypeForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  engineType: PropTypes.object.isRequired,
  engineSubType: PropTypes.object.isRequired
};

export default function EngineSubTypeForm({ engineType, engineSubType, submitCallback, onCancel }) {
  const { t } = useTranslation();
  const { createEngineSubType, updateEngineSubType } = useStore((state) => state);

  const EngineSubTypeSchema = Yup.object().shape({
    title: Yup.string().required(t('required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: engineSubType.id || '',
      title: engineSubType.title || '',
      SCE3: engineSubType.SCE3 || '',
      minPower: engineSubType.minPower || '',
      maxPower: engineSubType.maxPower || '',
      CO2Limit: engineSubType.CO2Limit || '',
      HCLimit: engineSubType.HCLimit || '',
      NOxLimit: engineSubType.NOxLimit || '',
      PMLimit: engineSubType.PMLimit || '',
      PNLimit: engineSubType.PNLimit || '',
      ALimit: engineSubType.ALimit || ''
    },
    validationSchema: EngineSubTypeSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const action = values.id ? updateEngineSubType : createEngineSubType;
      action(engineType, values).then((res) => {
        const index = engineType.subTypes.findIndex((subType) => subType.id === res.id);
        if (index < 0) engineType.subTypes.push(res);
        else engineType.subTypes[index] = res;
        return submitCallback(engineType);
      });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label={t('engine_subtype.title')}
                {...getFieldProps('title')}
                error={Boolean(touched?.title && errors?.title)}
                helperText={touched?.title && errors?.title}
              />
              <TextField
                fullWidth
                label={t('engine_subtype.min_power')}
                {...getFieldProps('minPower')}
                error={Boolean(touched?.minPower && errors?.minPower)}
                helperText={touched?.minPower && errors?.minPower}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <TextField
                fullWidth
                label={t('engine_subtype.max_power')}
                {...getFieldProps('maxPower')}
                error={Boolean(touched?.maxPower && errors?.maxPower)}
                helperText={touched?.maxPower && errors?.maxPower}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <Typography variant="subtitle2">{t('engine_subtype.limits')}</Typography>
              <TextField
                fullWidth
                label={t('engine_subtype.SCE3')}
                {...getFieldProps('SCE3')}
                error={Boolean(touched?.SCE3 && errors?.SCE3)}
                helperText={touched?.SCE3 && errors?.SCE3}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <TextField
                fullWidth
                label={t('engine_subtype.CO2')}
                {...getFieldProps('CO2Limit')}
                error={Boolean(touched?.CO2Limit && errors?.CO2Limit)}
                helperText={touched?.CO2Limit && errors?.CO2Limit}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <TextField
                fullWidth
                label={t('engine_subtype.HC')}
                {...getFieldProps('HCLimit')}
                error={Boolean(touched?.HCLimit && errors?.HCLimit)}
                helperText={touched?.HCLimit && errors?.HCLimit}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <TextField
                fullWidth
                label={t('engine_subtype.NOx')}
                {...getFieldProps('NOxLimit')}
                error={Boolean(touched?.NOxLimit && errors?.NOxLimit)}
                helperText={touched?.NOxLimit && errors?.NOxLimit}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <TextField
                fullWidth
                label={t('engine_subtype.PM')}
                {...getFieldProps('PMLimit')}
                error={Boolean(touched?.PMLimit && errors?.PMLimit)}
                helperText={touched?.PMLimit && errors?.PMLimit}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <TextField
                fullWidth
                label={t('engine_subtype.PN')}
                {...getFieldProps('PNLimit')}
                error={Boolean(touched?.PNLimit && errors?.PNLimit)}
                helperText={touched?.PNLimit && errors?.PNLimit}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <TextField
                fullWidth
                label={t('engine_subtype.A')}
                {...getFieldProps('ALimit')}
                error={Boolean(touched?.ALimit && errors?.ALimit)}
                helperText={touched?.ALimit && errors?.ALimit}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
            </Stack>
            <Grid container spacing={3} padding={3}>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    onClick={() => onCancel()}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {t('save')}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
