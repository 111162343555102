import { useTranslation } from 'react-i18next';
import { Card, Container } from '@material-ui/core';
import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { PATH_CLIENT } from '../routes/paths';
import RequestList from '../components/_dashboard/request/RequestList';
// ----------------------------------------------------------------------

export default function Request() {
  const { t } = useTranslation();

  return (
    <Page title={t('my_requests')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('my_requests')}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: t('my_requests') }]}
        />
        <Card>
          <RequestList />
        </Card>
      </Container>
    </Page>
  );
}
