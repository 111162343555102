import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Toolbar
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import moment from 'moment';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
import useStore from '../../../store/useStore';
import Scrollbar from '../../Scrollbar';
import RequestListHead from './RequestListHead';
import RequestMoreMenu from './RequestMoreMenu';
import { fDate, fToNow } from '../../../utils/formatTime';
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  if (array.size === 0) return array;
  array.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) return order;
    return a - b;
  });

  return array;
}

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  textAlign: 'center'
}));

const RootStyle = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'flex-end'
});

const RightBox = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export default function RequestList() {
  const { t } = useTranslation();
  const loading = useStore((state) => state.loadingRequests);
  const getRequests = useStore((state) => state.getRequests);
  const requests = useStore((state) => state.requests);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const [orderBy, setOrderBy] = useState('title');
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const TABLE_HEAD = [
    { id: 'title', label: t('ship.title'), alignRight: false },
    { id: 'status', label: t('status'), alignRight: false },
    { id: 'last_action', label: t('request.last_action'), alignRight: false },
    { id: '' }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;
  const isRequestEmpty = requests.length === 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filteredRequests = applySortFilter(requests, getComparator(order, orderBy));

  return (
    <DetailCard sx={{ p: 3 }}>
      <RootStyle>
        <Tooltip title={t('refresh')}>
          <RightBox>
            {loading && (
              <Typography
                align="right"
                variant="body2"
                sx={{ fontStyle: 'italic', color: 'text.secondary' }}
              >
                {t('refreshing')}...
              </Typography>
            )}
            <IconButton onClick={() => getRequests()}>
              <Icon icon={refreshOutline} />
            </IconButton>
          </RightBox>
        </Tooltip>
      </RootStyle>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <RequestListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredRequests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, ship, createdOn, status } = row;
                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell>{ship.title}</TableCell>
                      <TableCell>{status}</TableCell>
                      <TableCell>
                        {fDate(moment(createdOn))} ({fToNow(moment(createdOn))})
                      </TableCell>
                      <TableCell align="right">
                        <RequestMoreMenu id={id} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isRequestEmpty && !loading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper>
                      <Typography gutterBottom align="center" variant="subtitle1">
                        {t('request.no_requests')}
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={requests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DetailCard>
  );
}
