import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store/useStore';
import AssignedEditorsDialog from './AssignedEditorsDialog';

ShipMoreMenu.propTypes = {
  ship: PropTypes.object
};

export default function ShipMoreMenu({ ship }) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { deleteShip } = useStore((state) => state);

  const onDeleteClick = () => {
    deleteShip(ship.id);
    setIsOpen(false);
  };

  const user = useStore((state) => state.user);

  const { isUserShipAdmin } = useStore((state) => state);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user?.roles?.includes('ROLE_ADMIN')) {
      setIsAdmin(true);
    } else {
      isUserShipAdmin(ship.id).then((res) => setIsAdmin(res));
    }
  }, [ship.id, isUserShipAdmin, user?.roles]);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterLink} to={`${ship.id}`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('edit')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onDeleteClick()}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('delete')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {isAdmin && <AssignedEditorsDialog ship={ship} />}
      </Menu>
    </>
  );
}
