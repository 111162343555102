import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';

const EngineSubTypeSelect = ({ onSelect, selected, className, engine }) => {
  const { t } = useTranslation();
  const { subTypes } = engine.type;

  if (subTypes.length === 0) {
    return (
      <TextField
        fullWidth
        className={className}
        name="subtype"
        id="subtype"
        label={t('engine_subtype.title')}
        defaultValue={t('engine_subtype.none')}
        disabled
      />
    );
  }

  function getOptionLabel(option) {
    if (option.minPower <= engine.power && option.maxPower > engine.power)
      return `>> ${t(option.title)}`;
    return t(option.title);
  }

  return (
    <TextField
      className={className}
      fullWidth
      select
      name="subtypes"
      id="subtypes"
      label={t('engine_subtype.title')}
      defaultValue={t('engine_subtype.title')}
      SelectProps={{
        multiple: false,
        value: selected?.id || '',
        onChange: (e) => onSelect(subTypes.find((et) => e.target.value === et.id))
      }}
    >
      {subTypes?.map((option) => (
        <MenuItem className="py-10" key={option.id} value={option.id}>
          {getOptionLabel(option)}
        </MenuItem>
      ))}
    </TextField>
  );
};

EngineSubTypeSelect.defaultProps = {
  className: 'mw-100'
};

EngineSubTypeSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object,
  className: PropTypes.string,
  engine: PropTypes.object.isRequired
};

export default EngineSubTypeSelect;
