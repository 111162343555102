import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { useState } from 'react';
import PropTypes from 'prop-types';

const ConfirmationDialog = ({ open, options, onCancel, onConfirm, onClose }) => {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    dialogActionsProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    confirmationKeyword,
    confirmationKeywordTextFieldProps,
    hideCancelButton,
    buttonOrder
  } = options;

  const [confirmationKeywordValue, setConfirmationKeywordValue] = useState('');

  const confirmationButtonDisabled =
    confirmationKeyword && confirmationKeywordValue !== confirmationKeyword;

  const confirmationContent = (
    <>
      {confirmationKeyword && (
        <TextField
          onChange={(e) => setConfirmationKeywordValue(e.target.value)}
          value={confirmationKeywordValue}
          fullWidth
          {...confirmationKeywordTextFieldProps}
        />
      )}
    </>
  );

  const dialogActions = buttonOrder.map((buttonType) => {
    if (buttonType === 'cancel') {
      return (
        !hideCancelButton && (
          <Button color="error" key="cancel" {...cancellationButtonProps} onClick={onCancel}>
            {cancellationText}
          </Button>
        )
      );
    }

    if (buttonType === 'confirm') {
      return (
        <Button
          key="confirm"
          color="primary"
          disabled={confirmationButtonDisabled}
          {...confirmationButtonProps}
          onClick={onConfirm}
        >
          {confirmationText}
        </Button>
      );
    }

    throw new Error(`Supported button types are only "confirm" and "cancel", got: ${buttonType}`);
  });

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={allowClose ? onClose : null}>
      {title && (
        <DialogTitle {...titleProps} sx={{ pb: 2 }}>
          {title}
        </DialogTitle>
      )}
      {/* eslint-disable-next-line no-nested-ternary */}
      {content ? (
        <DialogContent {...contentProps}>
          {content}
          {confirmationContent}
        </DialogContent>
      ) : description ? (
        <DialogContent {...contentProps}>
          <DialogContentText>{description}</DialogContentText>
          {confirmationContent}
        </DialogContent>
      ) : (
        confirmationKeyword && (
          <DialogContent {...contentProps}>{confirmationContent}</DialogContent>
        )
      )}
      <DialogActions {...dialogActionsProps}>{dialogActions}</DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  options: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};
export default ConfirmationDialog;
