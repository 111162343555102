import React, { useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConfirmContext from '../contexts/ConfirmContext';
import ConfirmationDialog from '../components/@material-extend/ConfirmationDialog';

const getDefaultOptions = (t) => ({
  title: t('confirmation'),
  description: '',
  content: null,
  confirmationText: t('ok'),
  cancellationText: t('cancel'),
  dialogProps: {},
  dialogActionsProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
  titleProps: {},
  contentProps: {},
  allowClose: true,
  confirmationKeywordTextFieldProps: {},
  hideCancelButton: false,
  buttonOrder: ['cancel', 'confirm']
});

const buildOptions = (defaultOptions, options, t) => {
  const DEFAULT_OPTIONS = getDefaultOptions(t);

  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || {})
  };
  const dialogActionsProps = {
    ...(defaultOptions.dialogActionsProps || DEFAULT_OPTIONS.dialogActionsProps),
    ...(options.dialogActionsProps || {})
  };
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps || DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {})
  };
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps || DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {})
  };
  const titleProps = {
    ...(defaultOptions.titleProps || DEFAULT_OPTIONS.titleProps),
    ...(options.titleProps || {})
  };
  const contentProps = {
    ...(defaultOptions.contentProps || DEFAULT_OPTIONS.contentProps),
    ...(options.contentProps || {})
  };
  const confirmationKeywordTextFieldProps = {
    ...(defaultOptions.confirmationKeywordTextFieldProps ||
      DEFAULT_OPTIONS.confirmationKeywordTextFieldProps),
    ...(options.confirmationKeywordTextFieldProps || {})
  };

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    dialogActionsProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    confirmationKeywordTextFieldProps
  };
};

const ConfirmProvider = ({ children, defaultOptions = {} }) => {
  const [options, setOptions] = useState({});
  const [resolveReject, setResolveReject] = useState([]);
  const [resolve, reject] = resolveReject;
  const { t } = useTranslation();

  const confirm = useCallback(
    (options = {}) =>
      new Promise((resolve, reject) => {
        setOptions(options);
        setResolveReject([resolve, reject]);
      }),
    []
  );

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    if (reject) {
      reject();
      handleClose();
    }
  }, [reject, handleClose]);

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve();
      handleClose();
    }
  }, [resolve, handleClose]);

  return (
    <>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={buildOptions(defaultOptions, options, t)}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
};

ConfirmProvider.propTypes = {
  children: PropTypes.node,
  defaultOptions: PropTypes.object
};
export default ConfirmProvider;
