import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { useCallback } from 'react';
import { Box, Button, FormHelperText, Grid, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import useStore from '../../../store/useStore';
import UploadSingleFile from '../../upload/UploadSingleFile';
import { LabelStyle } from '../../../layouts/styles';
// ----------------------------------------------------------------------
HoursForm.propTypes = {
  engine: PropTypes.object,
  submitCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  reading: PropTypes.object
};

export default function HoursForm({ engine, submitCallback, onCancel, reading }) {
  const { t } = useTranslation();
  const { createEngineHourReading } = useStore((state) => state);

  const maxUploadSize = process.env.REACT_APP_MAX_UPLOAD;

  const HourSchema = Yup.object().shape({
    hours: Yup.object({
      hours: Yup.number().required(t('required')),
      proof: Yup.mixed()
        .required(t('required'))
        .test('fileSize', t('required'), (value) => value)
        .test('fileSize', t('max_file_size', { size: maxUploadSize }), (file) => {
          if (!file) return true;
          return file.size <= maxUploadSize;
        })
    }),
    engine: Yup.number()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      engine: engine.id,
      hours: {
        hours: reading?.reading || 0,
        proof: null
      }
    },
    validationSchema: HourSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      createEngineHourReading(values.engine, values.hours).then((res) => submitCallback(res));
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps } =
    formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('hours.proof', file);
      }
    },
    [setFieldValue]
  );

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label={t('engine.current_hours')}
                {...getFieldProps('hours.hours')}
                error={Boolean(touched?.hours?.hours && errors?.hours?.hours)}
                helperText={touched?.hours?.hours && errors?.hours?.hours}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              <Box sx={{ mb: 5 }}>
                <LabelStyle>{t('engine.proof')}</LabelStyle>
                <UploadSingleFile
                  maxSize={maxUploadSize}
                  accept="image/*,.pdf,.docx,.doc"
                  file={values.hours?.proof}
                  onDrop={handleDrop}
                  error={Boolean(touched?.hours?.proof && errors?.hours?.proof)}
                  subtext={t('engine.proof_explanation')}
                />
                {touched?.hours?.proof && errors?.hours?.proof && (
                  <FormHelperText error sx={{ px: 2 }}>
                    {touched?.hours?.proof && errors?.hours?.proof}
                  </FormHelperText>
                )}
              </Box>
            </Stack>
            <Grid container spacing={3} padding={3}>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    onClick={() => onCancel()}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {t('save')}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
