import { format, formatDistanceToNow } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';
import i18n from 'i18next';
import moment from 'moment';
// ----------------------------------------------------------------------

export function fYear(date) {
  if (!moment(date, moment.ISO_8601, true).isValid()) return '-';
  return format(new Date(date), 'yyyy');
}

export function fDate(date) {
  if (!moment(date, moment.ISO_8601, true).isValid()) return '-';
  return format(new Date(date), 'dd-MM-yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  const { language } = i18n;
  const locale = language === 'nl' ? nl : enUS;
  if (!moment(date, moment.ISO_8601, true).isValid()) return '-';
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale
  });
}
