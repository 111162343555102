import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  IconButton,
  Tooltip,
  TableHead,
  Toolbar,
  CardHeader,
  Box,
  Grid
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import plusSquareOutline from '@iconify/icons-eva/plus-square-outline';
import Scrollbar from '../../../Scrollbar';
import { DetailCard } from '../../../../layouts/styles';
import EngineTypeSelect from '../../ship/engine/EngineTypeSelect';
import EngineSubTypeForm from './EngineSubTypeForm';

const RootStyle = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between'
});

const IconBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  p: 1,
  borderRadius: 1
});

const RightBox = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
});

export default function EngineSubTypeList() {
  const { t } = useTranslation();

  const [editRow, setEditRow] = useState(null);
  const [selectedEngineType, setSelectedEngineType] = useState(null);

  const stopEditing = (res) => {
    if (res) setSelectedEngineType(res);
    setEditRow(null);
  };

  const createEngineSubType = () => {
    setEditRow({});
  };

  const onRowClick = (row) => {
    setEditRow(row);
  };

  if (editRow) {
    const title = editRow.id ? t('engine_subtype.edit') : t('engine_subtype.create');
    return (
      <DetailCard sx={{ p: 3 }}>
        <CardHeader style={{ paddingBottom: '1rem' }} title={title} />
        <EngineSubTypeForm
          engineSubType={editRow}
          engineType={selectedEngineType}
          submitCallback={(res) => stopEditing(res)}
          onCancel={() => stopEditing()}
        />
      </DetailCard>
    );
  }

  return (
    <DetailCard sx={{ p: 3 }}>
      <RootStyle>
        <Typography variant="h6">{t('engine.subtypes')}</Typography>
        <IconBox>
          <Tooltip title={t('add')}>
            <RightBox>
              <IconButton
                disabled={selectedEngineType === null}
                onClick={() => createEngineSubType()}
              >
                <Icon icon={plusSquareOutline} />
              </IconButton>
            </RightBox>
          </Tooltip>
        </IconBox>
      </RootStyle>
      <Scrollbar>
        <Grid container direction="row" padding="1em">
          <Grid item xs={12} md={6}>
            <EngineTypeSelect
              selected={selectedEngineType}
              onSelect={(engineType) => setSelectedEngineType(engineType)}
            />
          </Grid>
        </Grid>
        {selectedEngineType ? (
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan="2">{t('engine_subtype.title')}</TableCell>
                  <TableCell rowSpan="2">{t('engine_subtype.min_power')}</TableCell>
                  <TableCell rowSpan="2">{t('engine_subtype.max_power')}</TableCell>
                  <TableCell rowSpan="2">{t('engine_subtype.SCE3')}</TableCell>
                  <TableCell colSpan="6" align="center">
                    {t('engine_subtype.limits')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ display: 'none' }}>&nbsp;</TableCell>
                  <TableCell>{t('engine_subtype.CO2')}</TableCell>
                  <TableCell>{t('engine_subtype.HC')}</TableCell>
                  <TableCell>{t('engine_subtype.NOx')}</TableCell>
                  <TableCell>{t('engine_subtype.PM')}</TableCell>
                  <TableCell>{t('engine_subtype.PN')}</TableCell>
                  <TableCell>{t('engine_subtype.A')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedEngineType.subTypes.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="9">{t('engine_subtype.none')}</TableCell>
                  </TableRow>
                )}
                {selectedEngineType.subTypes.map((row) => (
                  <TableRow hover key={row.id} tabIndex={-1}>
                    <TableCell onClick={() => onRowClick(row)}>{row.title}</TableCell>
                    <TableCell>{row.minPower}</TableCell>
                    <TableCell>{row.maxPower}</TableCell>
                    <TableCell>{row.SCE3}</TableCell>
                    <TableCell>{row.CO2Limit}</TableCell>
                    <TableCell>{row.HCLimit}</TableCell>
                    <TableCell>{row.NOxLimit}</TableCell>
                    <TableCell>{row.PMLimit}</TableCell>
                    <TableCell>{row.PNLimit}</TableCell>
                    <TableCell>{row.ALimit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2">{t('engine_type.select')}</Typography>
        )}
      </Scrollbar>
    </DetailCard>
  );
}
