import { Box, Button, Grid, Stack } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack5';
import { LoadingButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import useStore from '../../../../store/useStore';
import { PATH_CLIENT } from '../../../../routes/paths';
import ShipInfo from '../../admin/request/overview/info/ShipInfo';
import EngineInfo from '../../admin/request/overview/info/EngineInfo';
import EngineHoursTable from '../../info/engine/EngineHoursTable';
import AnnualReportOverview from '../../info/ship/AnnualReportOverview';

const RequestInfo = ({ jumpToStep }) => {
  const { t } = useTranslation();
  const { wizardShip } = useStore((state) => state);
  const { createRequest, savingRequest } = useStore((state) => state);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const doRequest = () => {
    createRequest(wizardShip)
      .then(() => {
        enqueueSnackbar(t('request.added'), { variant: 'success' });
        navigate(`${PATH_CLIENT.request}`, {});
      })
      .catch((e) => {
        enqueueSnackbar(t('generic_error'), { variant: 'error' });
        console.log(e);
      });
  };

  return (
    <Grid sx={{ mt: 5 }} container spacing={3}>
      <Grid item xs={12}>
        <Stack sx={{ mb: 5 }} spacing={3}>
          <ShipInfo ship={wizardShip} loading={false} />
          <EngineInfo ship={wizardShip} />
          <EngineHoursTable ship={wizardShip} year={new Date().getFullYear()} />
          <AnnualReportOverview ship={wizardShip} year={new Date().getFullYear() - 1} />
        </Stack>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                onClick={() => jumpToStep(4)}
              >
                {t('previous')}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                variant="contained"
                loading={savingRequest}
                onClick={() => doRequest()}
              >
                {t('request.do_request')}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

RequestInfo.propTypes = {
  jumpToStep: PropTypes.func
};

export default RequestInfo;
