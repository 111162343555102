import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';
import { Box, Card, Link, Container, Typography } from '@material-ui/core';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { RegisterForm } from '../components/authentication/register';
import backgroundImage from '../_assets/login_image.png';
import LanguagePopover from '../layouts/dashboard/LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  maxWidth: 520,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  borderRadius: '0px'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 520,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function RegisterWithInvite() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  return (
    <RootStyle title="Register">
      <AuthLayout>
        <LanguagePopover />
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 }
          }}
        >
          {t('have_account')}? &nbsp;
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
            {t('login')}
          </Link>
        </Typography>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle />
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {t('register')}
            </Typography>
          </Box>
          <RegisterForm invite={searchParams.get('uuid')} />

          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            {t('accept_policy.part1')}&nbsp;
            <Link
              target="_blank"
              component={RouterLink}
              to={{
                pathname: `${process.env.REACT_APP_URL}/static/algemene_voorwaarden_02-2021.pdf`
              }}
              underline="always"
              sx={{ color: 'text.primary' }}
            >
              {t('accept_policy.part2')}
            </Link>
            &nbsp;{t('accept_policy.part3')}&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              {t('accept_policy.part4')}
            </Link>
            .
          </Typography>

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              {t('have_account')}?&nbsp;
              <Link to="/login" component={RouterLink}>
                {t('login')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
