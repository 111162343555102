import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Box, Button, Grid, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store/useStore';
import TranslationForm from '../../../TranslationForm';

FuelForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fuelType: PropTypes.object.isRequired,
  fuel: PropTypes.object.isRequired
};

export default function FuelForm({ fuel, fuelType, submitCallback, onCancel }) {
  const { t } = useTranslation();
  const { createFuel, updateFuel } = useStore((state) => state);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: fuel.id || '',
      title: fuel.title || '',
      unitOfMeasurement: fuel.unitOfMeasurement || '',
      TTW: fuel.TTW || '',
      specificWeight: fuel.specificWeight || ''
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const action = values.id ? updateFuel : createFuel;
      action(values, fuelType).then((res) => {
        const index = fuelType.fuels.findIndex((subType) => subType.id === res.id);
        if (index < 0) fuelType.fuels.push(res);
        else fuelType.fuels[index] = res;
        return submitCallback(fuelType);
      });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label={t('engine_subtype.title')}
                  {...getFieldProps('title')}
                  error={Boolean(touched?.title && errors?.title)}
                  helperText={touched?.title && errors?.title}
                />
                <TextField
                  fullWidth
                  label={t('fuels.uom')}
                  {...getFieldProps('unitOfMeasurement')}
                  error={Boolean(touched?.unitOfMeasurement && errors?.unitOfMeasurement)}
                  helperText={touched?.unitOfMeasurement && errors?.unitOfMeasurement}
                />
                <TextField
                  fullWidth
                  label={t('fuels.ttw')}
                  {...getFieldProps('TTW')}
                  error={Boolean(touched?.TTW && errors?.TTW)}
                  helperText={touched?.TTW && errors?.TTW}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t('fuels.specific_weight')}
                  {...getFieldProps('specificWeight')}
                  error={Boolean(touched?.specificWeight && errors?.specificWeight)}
                  helperText={touched?.specificWeight && errors?.specificWeight}
                  type="number"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      'wheel',
                      (e) => {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
              </Stack>
              <Grid container spacing={3} padding={3}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      onClick={() => onCancel()}
                    >
                      {t('cancel')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {t('save')}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <TranslationForm parentEntity={fuel} parentEntityType="Fuel" translationGroup="fuel" />
    </>
  );
}
