import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useStore from '../../../../store/useStore';
import TranslationForm from '../../../TranslationForm';
import FuelTypeSelect from '../fuels/FuelTypeSelect';
// ----------------------------------------------------------------------
EngineTypeForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  engineType: PropTypes.object.isRequired
};

export default function EngineTypeForm({ engineType, submitCallback, onCancel }) {
  const { t } = useTranslation();
  const { createEngineType, updateEngineType } = useStore((state) => state);
  const [selectedFuelTypes, setSelectedFuelTypes] = useState([]);
  const EngineTypeSchema = Yup.object().shape({
    code: Yup.string().required(t('required')),
    description: Yup.string().required(t('required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: engineType.id || '',
      code: engineType.code || '',
      description: engineType.description || '',
      postTreatmentOptional: engineType.postTreatmentOptional || false,
      ureumOptional: engineType.ureumOptional || false
    },
    validationSchema: EngineTypeSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      values.fuelTypes = selectedFuelTypes.map((ft) => ft.id);
      const action = values.id ? updateEngineType : createEngineType;
      action(values).then((res) => submitCallback(res));
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, values } =
    formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label={t('engine_type.code')}
                  {...getFieldProps('code')}
                  error={Boolean(touched?.code && errors?.code)}
                  helperText={touched?.code && errors?.code}
                />
                <TextField
                  fullWidth
                  label={t('engine_type.description')}
                  {...getFieldProps('description')}
                  error={Boolean(touched?.description && errors?.description)}
                  helperText={touched?.description && errors?.description}
                />
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Switch
                      onChange={(event) =>
                        setFieldValue('postTreatmentOptional', event.target.checked)
                      }
                      checked={values.postTreatmentOptional}
                    />
                  }
                  label={
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {t('engine_type.post_treatment_optional')}
                    </Typography>
                  }
                  sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'left' }}
                />
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Switch
                      onChange={(event) => setFieldValue('ureumOptional', event.target.checked)}
                      checked={values.ureumOptional}
                    />
                  }
                  label={
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {t('engine_type.ureum_optional')}
                    </Typography>
                  }
                  sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'left' }}
                />
              </Stack>
              <FuelTypeSelect
                selected={selectedFuelTypes}
                onSelect={setSelectedFuelTypes}
                multiple
              />
              <Grid container spacing={3} padding={3}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      onClick={() => onCancel()}
                    >
                      {t('cancel')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {t('save')}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      {engineType?.id && (
        <TranslationForm
          parentEntity={engineType}
          parentEntityType="EngineType"
          translationGroup="engine_type"
        />
      )}
    </>
  );
}
