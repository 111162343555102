import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// layouts
import { useSnackbar } from 'notistack5';
import i18n from 'i18next';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import backgroundImage from '../_assets/login_image.png';
import LanguagePopover from '../layouts/dashboard/LanguagePopover';
import useStore from '../store/useStore';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  maxWidth: 520,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  borderRadius: '0px'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 520,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const authenticated = useStore((state) => state.authenticated);
  const { loadingUser, user } = useStore((state) => state);
  const loggedOut = useStore((state) => state.loggedOut);
  const { enqueueSnackbar } = useSnackbar();
  const refresh = useStore((state) => state.refresh);

  useEffect(() => {
    if (loggedOut) {
      enqueueSnackbar(i18n.t('logged_out'), { variant: 'success' });
      refresh();
    }
    if (authenticated && 'email' in user && !loadingUser) navigate('/', { replace: true });
  }, [authenticated, navigate, loggedOut, enqueueSnackbar, refresh, user, loadingUser]);

  return (
    <RootStyle title="Login">
      <AuthLayout>
        <LanguagePopover />
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 }
          }}
        >
          {t('no_account')}? &nbsp;
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
            {t('register')}
          </Link>
        </Typography>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle />
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Login
            </Typography>
          </Stack>

          <LoginForm />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              {t('no_account')}?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                {t('register')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
