import getAxiosConnection from '../utils/axios';

const initialState = {
  fuelTypes: [],
  loadingFuelTypes: false,
  savingFuelType: false,
  savingFuel: false
};

const fuelSlice = (set, get) => ({
  ...initialState,
  getFuelTypes: async () => {
    set({ loadingFuelTypes: true });
    return getAxiosConnection()
      .get(`/fueltype`)
      .then((res) => {
        set({ fuelTypes: res.data });
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingFuelTypes: false });
      });
  },
  createFuelType: async (fuelType) => {
    set({ savingFuelType: true });
    return getAxiosConnection()
      .post(`fueltype`, { ...fuelType })
      .then((res) => {
        get().getFuelTypes();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingFuelType: false });
      });
  },
  updateFuelType: async (fuelType) => {
    set({ savingFuelType: true });
    return getAxiosConnection()
      .patch(`fueltype/${fuelType.id}`, { ...fuelType })
      .then((res) => {
        get().getFuelTypes();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingFuelType: false });
      });
  },
  createFuel: async (fuel, fuelType) => {
    set({ savingFuel: true });
    return getAxiosConnection()
      .post(`fuel`, { ...fuel })
      .post(`fueltype/${fuelType.id}/fuel`, { ...fuel, fuelType: fuelType.id })

      .then((res) => {
        get().getFuelTypes();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingFuel: false });
      });
  },
  updateFuel: async (fuel, fuelType) => {
    set({ savingFuel: true });
    return getAxiosConnection()
      .patch(`fuel/${fuel.id}`, { ...fuel, fuelType: fuelType.id })
      .then((res) => {
        get().getFuelTypes();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingFuel: false });
      });
  }
});

export default fuelSlice;
