import i18n from 'i18next';
import getAxiosConnection from '../utils/axios';

const initialState = {
  requests: [],
  loadingRequest: false,
  loadingRequests: false,
  savingRequest: false,
  currentRequest: null,
  loadingAdvice: false,
  downloadingLabel: false,
  removingRequest: false,
  recalculatingLabel: false,
  loadingLabelRequestHistory: false
};

const requestSlice = (set, get) => ({
  ...initialState,
  getRequest: async (id) => {
    const { language } = i18n;
    set({ loadingRequest: true });
    return getAxiosConnection()
      .get(`labelrequest/${id}?language=${language}`)
      .then((res) => {
        set({ currentRequest: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingRequest: false });
      });
  },
  getRequests: async () => {
    set({ loadingRequests: true });
    const { language } = i18n;
    return getAxiosConnection()
      .get(`labelrequest?language=${language}`)
      .then((res) => {
        set({ requests: res.data });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingRequests: false });
      });
  },
  getAllRequests: async () => {
    set({ loadingRequests: true });
    const { language } = i18n;
    return getAxiosConnection()
      .get(`alllabelrequest?language=${language}`)
      .then((res) => {
        set({ requests: res.data });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingRequests: false });
      });
  },
  createRequest: async (ship) => {
    set({ savingRequest: true });
    return getAxiosConnection()
      .get(`labelrequests/${ship.id}`)
      .then((res) => {
        set({ currentRequest: res.data });
        return res.data;
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ savingRequest: false });
      });
  },
  getAdvice: async (labelRequest) => {
    set({ loadingAdvice: true });
    return getAxiosConnection()
      .get(`labelrequest/${labelRequest.id}/advice`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ loadingAdvice: false });
      });
  },
  downloadLabel: async (id, documentNumber) => {
    set({ downloadingLabel: true });
    return getAxiosConnection('application/json', 'blob')
      .get(`label/${id}/download`)
      .then((response) => {
        const { data } = response;
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${documentNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return 'success';
      })
      .finally(() => {
        set({ downloadingLabel: false });
      });
  },
  getLabelRequestHistory: async (id) => {
    set({ loadingLabelRequestHistory: true });

    const { language } = i18n;
    return getAxiosConnection()
      .get(`/labelrequest/${id}/history?language=${language}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingLabelRequestHistory: false });
      });
  },
  recalculateLabel: async (id) => {
    set({ recalculatingLabel: true });

    return getAxiosConnection()
      .get(`/labelrequest/${id}/recalculate`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ recalculatingLabel: false });
      });
  },
  removeRequest: async (id) => {
    set({ removingRequest: true });
    getAxiosConnection()
      .delete(`labelrequest/${id}`)
      .then(() => {
        const oldRequests = get().requests;
        const requests = oldRequests.filter((request) => request.id !== id);
        set({ requests });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ removingRequest: false });
      });
  },
  assignLabel: async (id) => {
    set({ assigningLabel: true });

    return getAxiosConnection()
      .get(`/labelrequest/${id}/assign`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ assigningLabel: false });
      });
  },
  retractLabel: async (id) => {
    set({ assigningLabel: true });

    return getAxiosConnection()
      .get(`/labelrequest/${id}/retract`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ assigningLabel: false });
      });
  },
  setCurrentRequest: (currentRequest) => {
    set({ currentRequest });
  }
});

export default requestSlice;
