import { Icon } from '@iconify/react';
import personOutline from '@iconify/icons-eva/person-outline';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const authoritySidebarConfig = [
  {
    title: 'labels',
    path: '/authority/labels',
    icon: getIcon(personOutline)
  }
];

export default authoritySidebarConfig;
