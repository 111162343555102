import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@material-ui/lab';
import useStore from '../../../../store/useStore';
import { ShipForm } from '../../ship';

// eslint-disable-next-line react/prop-types
export const ShipInfo = ({ jumpToStep }) => {
  const { t } = useTranslation();
  const [ships, setShips] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const { wizardShip, setWizardShip } = useStore((state) => state);
  const { getShip, loadingShip } = useStore((state) => state);
  const { getShips, loadingShips } = useStore((state) => state);

  useEffect(() => {
    getShips().then((ships) => setShips(ships));
  }, [getShips, setShips]);

  useEffect(() => {
    if (ships.length === 1) {
      getShip(ships[0].id).then((ship) => {
        setWizardShip(ship);
      });
    }
  }, [getShip, setWizardShip, ships]);

  const inputChanged = (e) => {
    setShowForm(false);
    setWizardShip(e.target.value);
    getShip(e.target.value).then((ship) => {
      setWizardShip(ship);
    });
  };

  const onNewClick = () => {
    setWizardShip(null);
    setShowForm(true);
  };

  const onShipCreated = (ship) => {
    setWizardShip(ship);
    jumpToStep(2);
  };

  return (
    <Grid container spacing={3} padding={3}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="baseline"
        paddingTop="2em"
      >
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <TextField
              select
              fullWidth
              value={wizardShip?.id || ''}
              disabled={loadingShips}
              SelectProps={{ native: true }}
              label={t('ship.ship')}
              onChange={(event) => inputChanged(event)}
            >
              {!wizardShip && ships.length > 1 && <option value="">{t('select_ship')}</option>}
              {ships.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.title}
                </option>
              ))}
            </TextField>
          </Stack>
        </Grid>
        <Grid item xs={12} md={1}>
          -{t('or')}-
        </Grid>
        <Grid item xs={12} md={5} alignContent="middle">
          <Button fullWidth variant="contained" color="success" onClick={() => onNewClick()}>
            {t('new_ship')}
          </Button>
        </Grid>
      </Grid>
      {showForm && (
        <Grid item xs={12}>
          <Typography variant="h4">{t('new_ship')}</Typography>
          <ShipForm submitCallback={(s) => onShipCreated(s)} />
        </Grid>
      )}
      {!showForm && (
        <>
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                onClick={() => jumpToStep(0)}
              >
                {t('previous')}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                loading={loadingShip}
                loadingIndicator={<CircularProgress color="inherit" size={16} />}
                type="submit"
                variant="contained"
                onClick={() => jumpToStep(2)}
                disabled={!wizardShip}
              >
                {t('next')}
              </LoadingButton>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};
