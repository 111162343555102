import { Box, Card, Container, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import flashOutline from '@iconify/icons-eva/flash-outline';
import { capitalCase } from 'change-case';
import atOutline from '@iconify/icons-eva/at-outline';
import EngineTypeList from '../../components/_dashboard/admin/engines/EngineTypeList';
import EngineTitleList from '../../components/_dashboard/admin/engines/EngineTitleList';
import Page from '../../components/Page';
import { PATH_ADMIN } from '../../routes/paths';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import GenericCover from '../../components/GenericCover';
import EngineSubTypeList from '../../components/_dashboard/admin/engines/EngineSubTypeList';

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3)
  }
}));

export default function AdminEngines() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('engines');

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const TABS = [
    {
      value: 'engines',
      title: t('engines'),
      enabled: true,
      icon: <Icon icon={flashOutline} width={20} height={20} />,
      component: <EngineTypeList />
    },
    {
      value: 'subtypes',
      title: t('engine.subtypes'),
      enabled: true,
      icon: <Icon icon={flashOutline} width={20} height={20} />,
      component: <EngineSubTypeList />
    },
    {
      value: 'titles',
      title: t('engine_title.titles'),
      enabled: true,
      icon: <Icon icon={atOutline} width={20} height={20} />,
      component: <EngineTitleList />
    }
  ];

  return (
    <Page title={t('engines')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('engines')}
          links={[{ name: 'Dashboard', href: PATH_ADMIN.root }, { name: t('engines') }]}
        />
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative'
          }}
        >
          <GenericCover title={t('engines')} />

          <TabsWrapperStyle>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={handleChangeTab}
            >
              {TABS.map((tab) => (
                <Tab
                  disabled={!tab.enabled}
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.title)}
                />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
