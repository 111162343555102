const initialState = {
  wizardShip: null
};

const wizardSlice = (set) => ({
  ...initialState,
  setWizardShip: (wizardShip) => {
    set({ wizardShip });
  }
});

export default wizardSlice;
