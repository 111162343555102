import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { IconButton, InputAdornment, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack5';
import useStore from '../../../store/useStore';
// ----------------------------------------------------------------------

export default function ResetPasswordForm(token) {
  const { t } = useTranslation();
  const { setPassword, verifying } = useStore((state) => state);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('too_short'))
      .test('regex', t('special_char'), (val) => {
        const regExp = new RegExp('^.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?].*$');
        return regExp.test(val);
      })
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('password_match'))
      .required(t('required'))
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      setPassword(token, values.password).then((result) => {
        if (result === 'success') {
          enqueueSnackbar(t('password_reset'), {
            variant: 'success'
          });
          navigate('/login', { replace: true });
        } else {
          enqueueSnackbar(result, {
            variant: 'error'
          });
        }
      });
    }
  });

  if (!formik.isSubmitting && verifying) formik.setSubmitting(true);
  if (formik.isSubmitting && !verifying) formik.setSubmitting(false);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            aria-label={t('password')}
            type={showPassword ? 'text' : 'password'}
            label={t('password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Show password"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label={t('confirm_password')}
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            aria-label="reset password"
          >
            {t('reset')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
