import { useTranslation } from 'react-i18next';
import { Card, CardContent, Container, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { PATH_CLIENT } from '../routes/paths';
import useStore from '../store/useStore';
import HoursList from '../components/_dashboard/hours/HoursList';
import FuelConsumptionList from '../components/_dashboard/fuelConsumption/FuelConsumptionList';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  paddingTop: '2rem',
  marginTop: '1rem',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

export default function QuarterlyReport() {
  const { t } = useTranslation();

  const { getExpiredHourReadings, getQuarterlyFuelConsumptions } = useStore((state) => state);

  const readings = useStore((state) => state.expiredHourReadings);
  const fuelConsumptions = useStore((state) => state.quarterlyFuelConsumptions);

  useEffect(() => {
    getExpiredHourReadings();
    getQuarterlyFuelConsumptions();
  }, [getExpiredHourReadings, getQuarterlyFuelConsumptions]);

  return (
    <Page title={t('engine.hours')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('engine.quarterly_rapport')}
          links={[
            { name: 'Dashboard', href: PATH_CLIENT.root },
            { name: t('engine.quarterly_rapport') }
          ]}
        />
        {readings.length === 0 ? (
          <RootStyle>
            <CardContent
              sx={{
                color: 'grey.800',
                p: { md: 1 },
                pl: { md: 1 }
              }}
            >
              <Typography gutterBottom variant="h4">
                {t('hours.not_required')}
                <Typography variant="body2">{t('hours.every_quarter')}</Typography>
              </Typography>
            </CardContent>
          </RootStyle>
        ) : (
          <HoursList />
        )}
        {fuelConsumptions.length === 0 ? (
          <RootStyle>
            <CardContent
              sx={{
                color: 'grey.800',
                p: { md: 1 },
                pl: { md: 1 }
              }}
            >
              <Typography gutterBottom variant="h4">
                {t('fuel_consumption.not_required')}
                <Typography variant="body2">{t('fuel_consumption.every_quarter')}</Typography>
              </Typography>
            </CardContent>
          </RootStyle>
        ) : (
          <FuelConsumptionList />
        )}
      </Container>
    </Page>
  );
}
