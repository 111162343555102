import getAxiosConnection from '../utils/axios';

const initialState = {
  thirdParties: [],
  loadingThirdParties: false
};

const thirdPartySlice = (set) => ({
  ...initialState,
  getThirdParties: async () => {
    set({ loadingThirdParties: true });
    return getAxiosConnection()
      .get(`third-parties`)
      .then((res) => {
        set({ thirdParties: res.data });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingThirdParties: false });
      });
  }
});

export default thirdPartySlice;
