import PropTypes from 'prop-types';
import { CardHeader, Grid, Paper, Stack, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import Loader from 'react-loader-spinner';
import { DetailCard } from '../../../../layouts/styles';
import useStore from '../../../../store/useStore';
import { fDate, fYear } from '../../../../utils/formatTime';
import useDownloadList from '../../../../hooks/useDownloadList';

AnnualReportOverview.propTypes = {
  ship: PropTypes.object,
  year: PropTypes.number
};

export default function AnnualReportOverview({ ship, year }) {
  const { t } = useTranslation();
  const [report, setReport] = useState(null);
  const { getReportByYear } = useStore((state) => state);

  const [fuelConsumption, setFuelConsumption] = useState(null);
  const { getFuelConsumption, loadingFuelConsumption } = useStore((state) => state);
  const { downloadButton } = useDownloadList();

  useEffect(() => {
    getFuelConsumption(ship.id, year).then((res) => {
      if (res) {
        setFuelConsumption(res);
      }
    });
  }, [ship, getFuelConsumption, year]);

  useEffect(() => {
    if (ship) {
      getReportByYear(ship.id, year).then((res) => setReport(res));
    }
  }, [getReportByYear, year, setReport, ship]);

  const filteredFuelConsumptions = useMemo(
    () => fuelConsumption?.filter((fc) => fYear(fc.date) === year.toString()),
    [fuelConsumption, year]
  );

  if (loadingFuelConsumption || !filteredFuelConsumptions) {
    return (
      <Paper>
        <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
      </Paper>
    );
  }

  return (
    <DetailCard>
      <CardHeader style={{ paddingBottom: '1rem' }} title={t('year_reports')} />
      <Stack spacing={2} sx={{ p: 3 }}>
        {report ? (
          <Stack key={`year_report${report.id}`}>
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="body2">{t('report.year')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{report.year}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('report.units_transported')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{report.unitsTransported}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('report.ton_km_performance')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{report.tonKilometerPerformance}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('report.created')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{fDate(report.added)}</Typography>
              </Grid>
              {report.engineOperatingHours.map((eoh, index) => (
                <Grid container key={`eoh${index}`}>
                  <Grid item xs={3}>
                    <Typography variant="body2">{t('engine.title')}:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">{eoh.engine.title}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">{t('engine.running_hours')}:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">{eoh.operatingHours}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Stack>
        ) : (
          <Typography variant="body2">{t('no_report_for_given_year', { year })}</Typography>
        )}
      </Stack>

      <Stack key="fuelConsumptions" spacing={2} sx={{ p: 3 }}>
        {filteredFuelConsumptions.length === 0 ? (
          <Typography sx={{ pt: 0.5 }} variant="body2">
            {t('no_fuel_for_given_year', { year })}
          </Typography>
        ) : (
          filteredFuelConsumptions.map((fc) => (
            <Stack key={fc.id}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="body2">{t('fuels.fuel')}:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    {fc.quantity} {fc.fuel.uom} {fc.fuel.fuel}
                  </Typography>
                </Grid>
                {fc.files.map((file, index) => (
                  <Grid key={`file_${index}`} container>
                    <Grid item xs={3}>
                      &nbsp;
                    </Grid>
                    <Grid style={{ cursor: 'pointer' }} item xs={1}>
                      {downloadButton(file.fileName)}
                    </Grid>
                    <Grid item alignItems="center" justifyContent="center" xs={8}>
                      <Typography sx={{ pt: 0.5 }} variant="body2">
                        {file.originalName}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          ))
        )}
      </Stack>
    </DetailCard>
  );
}
