import { useTranslation } from 'react-i18next';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Card,
  CardHeader,
  Grid,
  Stack,
  IconButton,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import cloudDownloadOutline from '@iconify/icons-eva/cloud-download-outline';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@material-ui/lab';
import useStore from '../../../../../store/useStore';

const DownloadText = styled(Typography)({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  '&:hover': {
    color: 'green',
    cursor: 'pointer'
  }
});

RequestLabel.propTypes = {
  request: PropTypes.object,
  loading: PropTypes.bool
};

export default function RequestLabel({ request, loading }) {
  const { t } = useTranslation();
  const { downloadLabel, downloadingLabel } = useStore((state) => state);
  const { recalculateLabel, recalculatingLabel } = useStore((state) => state);
  const [label, setLabel] = useState(null);
  const [showCalculation, setShowCalculation] = useState(false);
  const { getAdvice, loadingAdvice } = useStore((state) => state);
  const { assigningLabel, assignLabel, retractLabel } = useStore((state) => state);

  useEffect(() => {
    if (request) getAdvice(request).then((label) => setLabel(label));
  }, [request, getAdvice, setLabel]);

  const recalculate = (id) => {
    recalculateLabel(id).then((res) => {
      setLabel(res);
    });
  };

  const assignLabelClick = (id) => {
    assignLabel(id).then((res) => setLabel(res));
  };

  const retractLabelClick = (id) => {
    retractLabel(id).then((res) => setLabel(res));
  };

  function toggleCalculation() {
    setShowCalculation(!showCalculation);
  }

  if (loading || recalculatingLabel || loadingAdvice) {
    return (
      <Card>
        <CardHeader title={t('request.label')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title={t('request.label')}
        action={
          <IconButton onClick={() => recalculate(request.id)}>
            <Icon icon={refreshOutline} width={20} height={20} />
          </IconButton>
        }
      />
      {label ? (
        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack direction="row">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body2">{t('label.documentNumber')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{label.documentNumber}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('label.climate')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{label.climateLabel}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('label.air_quality')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{label.airQualityLabel}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('download')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                {downloadingLabel ? (
                  <Loader type="ThreeDots" color="#00BFFF" height={20} width={20} />
                ) : (
                  <DownloadText onClick={() => downloadLabel(label.id, label.documentNumber)}>
                    <Icon icon={cloudDownloadOutline} />
                  </DownloadText>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{t('label.status')}:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">
                  {label.active ? t('active') : t('inactive')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {!label.active ? (
                  <LoadingButton
                    loading={assigningLabel}
                    type="submit"
                    variant="contained"
                    onClick={() => assignLabelClick(request.id)}
                  >
                    {t('label.assign')}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    loading={assigningLabel}
                    type="submit"
                    variant="contained"
                    onClick={() => retractLabelClick(request.id)}
                  >
                    {t('label.retract')}
                  </LoadingButton>
                )}
                <LoadingButton
                  loading={assigningLabel}
                  type="submit"
                  variant="contained"
                  onClick={() => toggleCalculation()}
                  className="ml-20"
                  sx={{ ml: '1rem' }}
                >
                  {t('label.calculation')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      ) : (
        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack direction="row">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body2">{t('label.no_label')}</Typography>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      )}
      {showCalculation ? (
        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack direction="row">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TableContainer sx={{ minWidth: 550 }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">{t('label.estimated_mechanical_power')}</TableCell>
                        <TableCell align="left">
                          {label?.calculation?.estimatedMechanicalPower}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">{t('label.estimated_nox_emission')}</TableCell>
                        <TableCell align="left">
                          {label?.calculation?.estimatedNoxEmission}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">{t('label.estimated_pm_emission')}</TableCell>
                        <TableCell align="left">
                          {label?.calculation?.estimatedPmEmission}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">{t('label.total_max_kwh')}</TableCell>
                        <TableCell align="left">{label?.calculation?.totalMaxKwh}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">{t('label.total_operating_hours')}</TableCell>
                        <TableCell align="left">
                          {label?.calculation?.totalOperatingHours}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          {t('label.total_weighted_average_CO2_per_kwh')}
                        </TableCell>
                        <TableCell align="left">
                          {label?.calculation?.totalWeightedAverageCO2PerKwh}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          {t('label.total_weighted_average_fuel_per_kwh')}
                        </TableCell>
                        <TableCell align="left">
                          {label?.calculation?.totalWeightedAverageFuelPerKwh}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          {t('label.total_weighted_average_nox_per_kwh')}
                        </TableCell>
                        <TableCell align="left">
                          {label?.calculation?.totalWeightedAverageNoxPerKwh}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          {t('label.total_weighted_average_pm_per_kwh')}
                        </TableCell>
                        <TableCell align="left">
                          {label?.calculation?.totalWeightedAveragePmPerKwh}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
    </Card>
  );
}
