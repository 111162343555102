import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useEffect, useState } from 'react';
import EngineHoursTable from '../../../info/engine/EngineHoursTable';
import AnnualReportOverview from '../../../info/ship/AnnualReportOverview';
import { fYear } from '../../../../../utils/formatTime';

RequestDocuments.propTypes = {
  request: PropTypes.object,
  loading: PropTypes.bool
};

export default function RequestDocuments({ request, loading }) {
  const { t } = useTranslation();
  const { ship } = request;
  const d = new Date();
  const year = d.getFullYear() - 1;
  const [selectedYear, setSelectedYear] = useState(year);
  useEffect(() => {
    if (request) {
      setSelectedYear(fYear(request.createdOn) - 1);
    }
  }, [request, setSelectedYear]);
  if (!request || !ship || loading) {
    return (
      <Card>
        <CardHeader title={t('request.documents')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Grid key="adminRequestDocuments" container spacing={3}>
      <Grid item xs={12}>
        <Grid>
          <FormControl fullWidth>
            <InputLabel>{selectedYear}</InputLabel>
            <Select
              label={selectedYear}
              value={selectedYear || ''}
              onChange={(v) => setSelectedYear(v.target.value)}
            >
              {Array(43)
                .fill()
                .map((a, i) => (
                  <MenuItem key={i} value={i + 2017}>
                    {i + 2017}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Stack spacing={3}>
          <EngineHoursTable ship={ship} year={selectedYear} />
          <AnnualReportOverview ship={ship} year={selectedYear} />
        </Stack>
      </Grid>
    </Grid>
  );
}
