import { Icon } from '@iconify/react';
import menu2Outline from '@iconify/icons-eva/menu-2-outline';
import personOutline from '@iconify/icons-eva/person-outline';
import flashOutline from '@iconify/icons-eva/flash-outline';
import funnelOutline from '@iconify/icons-eva/funnel-outline';
import { PATH_ADMIN } from '../../routes/paths';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const adminSidebarConfig = [
  {
    title: 'users',
    path: PATH_ADMIN.users,
    icon: getIcon(personOutline)
  },
  {
    title: 'request.requests',
    path: PATH_ADMIN.requests,
    icon: getIcon(menu2Outline)
  },
  {
    title: 'engines',
    path: PATH_ADMIN.engines,
    icon: getIcon(flashOutline)
  },
  {
    title: 'fuels.fuel',
    path: PATH_ADMIN.fuels,
    icon: getIcon(funnelOutline)
  }
];
export default adminSidebarConfig;
