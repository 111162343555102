import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Box, Button, Grid, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store/useStore';
import TranslationForm from '../../../TranslationForm';
// ----------------------------------------------------------------------
FuelTypeForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fuelType: PropTypes.object.isRequired
};

export default function FuelTypeForm({ fuelType, submitCallback, onCancel }) {
  const { t } = useTranslation();
  const { createFuelType, updateFuelType } = useStore((state) => state);

  const FuelTypeSchema = Yup.object().shape({
    description: Yup.string().required(t('required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: fuelType.id || '',
      description: fuelType.description || ''
    },
    validationSchema: FuelTypeSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const action = values.id ? updateFuelType : createFuelType;
      action(values).then((res) => submitCallback(res));
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label={t('fuel_type.description')}
                  {...getFieldProps('description')}
                  error={Boolean(touched?.description && errors?.description)}
                  helperText={touched?.description && errors?.description}
                />
              </Stack>
              <Grid container spacing={3} padding={3}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      onClick={() => onCancel()}
                    >
                      {t('cancel')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {t('save')}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      {fuelType?.id && (
        <TranslationForm
          parentEntity={fuelType}
          parentEntityType="FuelType"
          translationGroup="fuel_type"
        />
      )}
    </>
  );
}
