import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
  IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import personRemoveOutline from '@iconify/icons-eva/person-remove-outline';
import arrowheadUpOutline from '@iconify/icons-eva/arrowhead-up-outline';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSnackbar } from 'notistack5';
import arrowheadDownOutline from '@iconify/icons-eva/arrowhead-down-outline';
import { fStringCapitalizeFirstLetter } from '../../../utils/formatString';
import InviteMemberModal from './InviteUserModal';
import useConfirm from '../../../hooks/useConfirm';
import useStore from '../../../store/useStore';

OrganizationMembers.propTypes = {
  organization: PropTypes.object
};

export default function OrganizationMembers({ organization }) {
  const [localOrganization, setLocalOrganization] = useState(organization);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { removeUser, promoteUser, demoteUser } = useStore((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const user = useStore((state) => state.user);

  const handleRemoveMember = (memberId) => {
    confirm({
      title: t('invitation.confirmation.remove_title'),
      description: t('invitation.confirmation.remove'),
      confirmationText: t('remove')
    })
      .then(() => {
        removeUser(memberId)
          .then((res) => {
            const { members } = res;
            const updatedOrganization = { ...localOrganization, members };
            setLocalOrganization(updatedOrganization);
            enqueueSnackbar(t('organization.removed_user'), {
              variant: 'success'
            });
          })
          .catch((error) => console.log(error));
      })
      .catch(() => {});
  };

  const handlePromoteMember = (memberId) => {
    confirm({
      title: t('organization.confirmation.promote_title'),
      description: t('organization.confirmation.promote'),
      confirmationText: t('promote')
    })
      .then(() => {
        promoteUser(memberId)
          .then((res) => {
            const { members } = res;
            const updatedOrganization = { ...localOrganization, members };
            setLocalOrganization(updatedOrganization);
            enqueueSnackbar(t('organization.promoted_user'), {
              variant: 'success'
            });
          })
          .catch(() =>
            enqueueSnackbar(t('organization.promote_user_error'), {
              variant: 'error'
            })
          );
      })
      .catch(() => {});
  };

  const handleDemoteMember = (memberId) => {
    confirm({
      title: t('organization.confirmation.demote_title'),
      description: t('organization.confirmation.demote'),
      confirmationText: t('demote')
    })
      .then(() => {
        demoteUser(memberId)
          .then((res) => {
            const { members } = res;
            const updatedOrganization = { ...localOrganization, members };
            setLocalOrganization(updatedOrganization);
            enqueueSnackbar(t('organization.demoted_user'), {
              variant: 'success'
            });
          })
          .catch(() =>
            enqueueSnackbar(t('organization.demote_user_error'), {
              variant: 'error'
            })
          );
      })
      .catch(() => {});
  };

  const isAdmin = localOrganization?.isAdmin;
  return (
    <>
      {isAdmin && (
        <InviteMemberModal
          isOpen={modalIsOpen}
          close={() => setModalIsOpen(false)}
          organization={localOrganization}
        />
      )}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5} mb={5}>
        <Typography variant="h4" gutterBottom>
          {fStringCapitalizeFirstLetter(t('members'))}
        </Typography>
        {isAdmin && (
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setModalIsOpen(true)}
          >
            {t('invite_member')}
          </Button>
        )}
      </Stack>
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{fStringCapitalizeFirstLetter(t('name'))}</TableCell>
                  <TableCell>{fStringCapitalizeFirstLetter(t('email'))}</TableCell>
                  <TableCell>{fStringCapitalizeFirstLetter(t('role'))}</TableCell>
                  {isAdmin && <TableCell>{fStringCapitalizeFirstLetter(t('actions'))}</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {localOrganization.members.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell>
                      {member.user.firstName} {member.user.lastName}
                    </TableCell>
                    <TableCell>{member.user.email}</TableCell>
                    <TableCell>{member.roles.includes('ROLE_ADMIN') ? 'Admin' : 'User'}</TableCell>
                    {isAdmin && user.id !== member.user.id && (
                      <TableCell>
                        <IconButton onClick={() => handleRemoveMember(member.id)}>
                          <Icon icon={personRemoveOutline} />
                        </IconButton>
                        {member.roles.includes('ROLE_ADMIN') ? (
                          <IconButton onClick={() => handleDemoteMember(member.id)}>
                            <Icon icon={arrowheadDownOutline} />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handlePromoteMember(member.id)}>
                            <Icon icon={arrowheadUpOutline} />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
