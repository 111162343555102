import { useTranslation } from 'react-i18next';
import { Card, Container } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import StepZilla from 'react-stepzilla';
import 'react-stepzilla/src/css/main.css';
import Page from '../../Page';
import HeaderBreadcrumbs from '../../HeaderBreadcrumbs';
import { PATH_CLIENT } from '../../../routes/paths';
import steps from './steps';
// ----------------------------------------------------------------------

const DetailCard = styled(Card)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  textAlign: 'center'
}));

export default function LabelRequestProcess() {
  const { t } = useTranslation();

  return (
    <Page title={t('new_request')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('new_request')}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: t('new_request') }]}
        />
        <Card>
          <DetailCard sx={{ p: 3 }}>
            <StepZilla
              steps={steps}
              stepsNavigation={false}
              preventEnterSubmission
              showNavigation={false}
              nextTextOnFinalActionStep="Save"
              onStepChange={(step) => window.sessionStorage.setItem('step', step)}
            />
          </DetailCard>
        </Card>
      </Container>
    </Page>
  );
}
