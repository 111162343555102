import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { AnimatePresence, motion } from 'framer-motion';
// material
import { alpha, styled } from '@material-ui/core/styles';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
  Typography
} from '@material-ui/core';
// utils
import { useTranslation } from 'react-i18next';
import { fData } from '../../utils/formatNumber';
//
import { MIconButton } from '../@material-extend';
import { varFadeInRight } from '../animate';
import { UploadIllustration } from '../../_assets';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object,
  subtext: PropTypes.string
};

export default function UploadMultiFile({
  error,
  files,
  onRemove,
  onRemoveAll,
  sx,
  subtext,
  ...other
}) {
  const hasFile = files.length > 0;
  const { t } = useTranslation();

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  function getFileName(file) {
    if (file instanceof File) return isString(file) ? file : file.name;
    return file.originalName;
  }

  function getFileSize(file) {
    if (file instanceof File) return isString(file) ? '' : fData(file.size);
    return fData(file.size);
  }

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ width: '100%', p: 0, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            {t('upload.drop')}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {subtext}
          </Typography>

          <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
            <AnimatePresence>
              {files.map((file, index) => (
                <ListItem
                  onClick={(e) => e.stopPropagation()}
                  key={index}
                  component={motion.div}
                  {...varFadeInRight}
                  sx={{
                    my: 1,
                    py: 0.75,
                    px: 2,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: 'background.paper'
                  }}
                >
                  <ListItemIcon>
                    <Icon icon={fileFill} width={28} height={28} />
                  </ListItemIcon>
                  <ListItemText
                    primary={getFileName(file)}
                    secondary={getFileSize(file)}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                  <ListItemSecondaryAction>
                    <MIconButton
                      edge="end"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemove(file);
                      }}
                    >
                      <Icon icon={closeFill} />
                    </MIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </AnimatePresence>
          </List>
        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={onRemoveAll} sx={{ mr: 1.5 }}>
            {t('remove_all')}
          </Button>
        </Stack>
      )}
    </Box>
  );
}
