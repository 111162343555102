import getAxiosConnection from '../utils/axios';

const initialState = {
  sendingMessage: false
};

const messageSlice = (set) => ({
  ...initialState,
  sendHelpMessage: async (message) => {
    set({ sendingMessage: true });
    return getAxiosConnection()
      .post('message/help', { message })
      .then((res) => res)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ sendingMessage: false });
      });
  }
});

export default messageSlice;
