import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import peopleOutline from '@iconify/icons-eva/people-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import emailOutline from '@iconify/icons-eva/email-outline';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import arrowheadUpOutline from '@iconify/icons-eva/arrowhead-up-outline';
import { useSnackbar } from 'notistack5';
import arrowheadDownOutline from '@iconify/icons-eva/arrowhead-down-outline';
import useStore from '../../../../store/useStore';
import { PATH_ADMIN } from '../../../../routes/paths';

UserMoreMenu.propTypes = {
  id: PropTypes.number,
  email: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default function UserMoreMenu({ id, email, isAdmin }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { deleteUser, passwordReset, impersonateUser, promoteAdminUser, demoteAdminUser } =
    useStore((state) => state);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onDeleteClick = () => {
    deleteUser(id)
      // .then((res) => console.log(res))
      .catch((err) => {
        if (err.detail === 'ships/non-empty') {
          enqueueSnackbar('Gebruiker niet verwijderd, deze heeft schepen.', { variant: 'error' });
        }
      });
    setIsOpen(false);
  };

  const onImpersonateClick = () => {
    impersonateUser(email);
  };

  const onResetPasswordClick = () => {
    passwordReset(email);
    setIsOpen(false);
  };

  const onPromoteClick = () => {
    promoteAdminUser(id).then(() =>
      enqueueSnackbar('Gebruiker gepromoveerd', { variant: 'success' })
    );
    setIsOpen(false);
  };

  const onDemoteClick = () => {
    demoteAdminUser(id).then(() =>
      enqueueSnackbar('Gebruiker gedemoveerd', { variant: 'success' })
    );
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          component={RouterLink}
          to={generatePath(PATH_ADMIN.user, { id })}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('edit')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onResetPasswordClick()}>
          <ListItemIcon>
            <Icon icon={emailOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={t('user.mail_password')}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onDeleteClick()}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('delete')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => (isAdmin ? onDemoteClick() : onPromoteClick())}
        >
          <ListItemIcon>
            <Icon
              icon={isAdmin ? arrowheadDownOutline : arrowheadUpOutline}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary={isAdmin ? t('demote') : t('promote')}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onImpersonateClick()}>
          <ListItemIcon>
            <Icon icon={peopleOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('impersonate')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
