import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack5';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Card,
  Grid,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  Stack,
  Box,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@material-ui/lab';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useState } from 'react';
import { PATH_ADMIN } from '../../../../routes/paths';

UserForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
  onSubmit: PropTypes.func
};

export default function UserForm({ isEdit, currentUser, onSubmit }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const UserSchema = Yup.object().shape({
    email: Yup.string().required(t('required')).email(),
    firstName: Yup.string().required(t('required')),
    lastName: Yup.string().required(t('required')),
    newUser: Yup.boolean(),
    password: Yup.string().when('newUser', {
      is: true,
      then: Yup.string()
        .required(t('required'))
        .min(8, t('too_short'))
        .test('regex', t('special_char'), (val) => {
          const regExp = new RegExp('^.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?].*$');
          return regExp.test(val);
        })
    }),
    confirmPassword: Yup.string().when('newUser', {
      is: true,
      then: Yup.string()
        .required(t('required'))
        .oneOf([Yup.ref('password'), null], t('password_match'))
    })
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: currentUser?.email || '',
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      isActive: !!currentUser?.isActive,
      isAdmin: !!currentUser?.roles.includes('ROLE_ADMIN'),
      isApi: !!currentUser?.roles.includes('ROLE_API'),
      newUser: !isEdit,
      password: '',
      confirmPassword: ''
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        if (isEdit) {
          delete values.password;
          delete values.confirmPassword;
          values.id = currentUser.id;
        }
        values.roles = values.isAdmin ? ['ROLE_ADMIN'] : ['ROLE_USER'];
        if (values.isApi) {
          values.roles.push('ROLE_API');
        }
        onSubmit(values)
          .then(() => {
            setSubmitting(false);
            enqueueSnackbar(!isEdit ? t('user.created') : t('user.updated'), {
              variant: 'success'
            });
            navigate(PATH_ADMIN.users);
          })
          .catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error' });
          });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label={t('user.email')}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  label={t('first_name')}
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  fullWidth
                  label={t('last_name')}
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
                {!isEdit && (
                  <>
                    <TextField
                      fullWidth
                      autoComplete="current-password"
                      type={showPassword ? 'text' : 'password'}
                      label={t('password')}
                      aria-label={t('password')}
                      {...getFieldProps('password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                              <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                    <TextField
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      label={t('confirm_password')}
                      {...getFieldProps('confirmPassword')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                              <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                    />
                  </>
                )}
                <FormControlLabel
                  labelPlacement="start"
                  control={<Switch {...getFieldProps('isActive')} checked={values.isActive} />}
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        {t('user.active')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {t('user.active_explanation')}
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                />
                <FormControlLabel
                  labelPlacement="start"
                  control={<Switch {...getFieldProps('isAdmin')} checked={values.isAdmin} />}
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        {t('user.admin')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {t('user.admin_explanation')}
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                />
                <FormControlLabel
                  labelPlacement="start"
                  control={<Switch {...getFieldProps('isApi')} checked={values.isApi} />}
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        {t('user.api_user')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {t('user.api_user_explanation')}
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                />
              </Stack>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {isEdit ? t('edit') : t('save')}
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
