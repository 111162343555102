import PropTypes from 'prop-types';
import { Card, CardHeader, Grid, Stack } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import useStore from '../../../../../store/useStore';
import ShipInfo from './info/ShipInfo';
import EngineInfo from './info/EngineInfo';
import UserInfo from './info/UserInfo';
import RequestInfo from './info/RequestInfo';

RequestDetails.propTypes = {
  request: PropTypes.object,
  loading: PropTypes.bool
};

export default function RequestDetails({ request, loading }) {
  const { t } = useTranslation();
  const { getShip, loadingShip } = useStore((state) => state);
  const { getUser, loadingUser } = useStore((state) => state);
  const [ship, setShip] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (request?.ship) {
      getShip(request.ship.id).then((res) => setShip(res));
      getUser(request.requester.id).then((res) => setUser(res));
    }
  }, [getShip, getUser, request]);

  if (!request) {
    return (
      <Card>
        <CardHeader title={t('request.ship_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack spacing={3}>
          <RequestInfo request={request} loading={loading} />
          <UserInfo user={user} loading={loadingUser} />
          <ShipInfo ship={ship} loading={loadingShip} />
          <EngineInfo ship={ship} />
        </Stack>
      </Grid>
    </Grid>
  );
}
