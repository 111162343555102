import getAxiosConnection from '../utils/axios';

const initialState = {
  loadingTranslations: false,
  savingTranslations: false,
  loadingTranslationFields: false,
  translations: [],
  translationFields: []
};

const translationSlice = (set) => ({
  ...initialState,
  createTranslation: async (type, id, values) => {
    set({ savingTranslations: true });
    return getAxiosConnection()
      .post(`translations/${type}/${id}`, { ...values })
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingTranslations: false });
      });
  },
  getTranslations: async (type, id) => {
    set({ loadingTranslations: true });
    return getAxiosConnection()
      .get(`translations/${type}/${id}`)
      .then((res) => {
        set({ translations: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingTranslations: false });
      });
  },
  getTranslationFields: async (type) => {
    set({ loadingTranslationFields: true });
    return getAxiosConnection()
      .get(`translations/fields/${type}`)
      .then((res) => {
        set({ translationFields: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingTranslationFields: false });
      });
  },
  updateTranslation: async (type, translationId, values) => {
    set({ savingTranslations: true });
    return getAxiosConnection()
      .patch(`translations/${type}/${translationId}`, { ...values })
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingTranslations: false });
      });
  }
});

export default translationSlice;
