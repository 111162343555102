import getAxiosConnection from '../utils/axios';

const initialState = {
  labels: [],
  loadingLabel: false,
  loadingLabels: false,
  currentLabel: null
};

const labelSlice = (set, get) => ({
  ...initialState,
  getLabel: async (id) => {
    if (get().currentLabel && Number(get().currentLabel.id) === Number(id))
      return get().currentLabel;
    set({ loadingLabel: true });
    return getAxiosConnection()
      .get(`label/${id}`)
      .then((res) => {
        set({ currentLabel: res.data });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingLabel: false });
      });
  },
  getLabels: async () => {
    set({ loadingLabels: true });
    return getAxiosConnection()
      .get(`labels`)
      .then((res) => {
        set({ labels: res.data });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingLabels: false });
      });
  },
  setCurrentLabel: (currentLabel) => {
    set({ currentLabel });
  }
});

export default labelSlice;
