import PropTypes from 'prop-types';
import { Typography, Stack, Grid, CardHeader } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useEffect, useState } from 'react';
import useStore from '../../../../../../store/useStore';
import { DetailCard } from '../../../../../../layouts/styles';

EngineInfo.propTypes = {
  ship: PropTypes.object
};

export default function EngineInfo({ ship }) {
  const { t } = useTranslation();
  const { getEngines, loadingEngines } = useStore((state) => state);
  const [engines, setEngines] = useState([]);

  useEffect(() => {
    if (ship) {
      getEngines(ship.id).then((res) => setEngines(res));
    }
  }, [ship, getEngines]);

  if (loadingEngines) {
    return (
      <DetailCard>
        <CardHeader title={t('request.engine_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
            <Typography variant="body2">{t('engine.loading_details')}</Typography>
          </Grid>
        </Grid>
      </DetailCard>
    );
  }

  if (!engines || engines.length === 0) {
    return (
      <DetailCard>
        <CardHeader title={t('request.engine_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Typography variant="body2">{t('no_engines')}</Typography>
          </Grid>
        </Grid>
      </DetailCard>
    );
  }

  return (
    <DetailCard>
      <CardHeader title={t('request.engine_info')} />
      <Stack spacing={2} sx={{ p: 3 }}>
        {engines.map((engine) => (
          <Grid key={engine.id} container>
            <Grid item xs={3}>
              <Typography variant="body2">{t('engine.title')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{engine.title}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('engine.type')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{engine.type?.description}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('engine.power')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{engine.power}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('engine.current_hours')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{engine?.latestReading?.reading}</Typography>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </DetailCard>
  );
}
