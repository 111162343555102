import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Link, Drawer, Avatar, Button, Typography } from '@material-ui/core';
// components
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import sidebarConfig from './SidebarConfig';
import adminSidebarConfig from './AdminSidebarConfig';
import apiSidebarConfig from './ApiSidebarConfig';
import authoritySidebarConfig from './AuthoritySidebarConfig';
import useStore from '../../store/useStore';
import { PATH_ADMIN } from '../../routes/paths';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(3),
  '& > div': {
    padding: theme.spacing(2),
    '& > div': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      fontSize: '0.9rem'
    }
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const user = useStore((state) => state.user);
  const isAdmin = user?.roles?.includes('ROLE_ADMIN');
  const isApi = user?.roles?.includes('ROLE_API');
  const isAuthority = user?.roles?.includes('ROLE_AUTHORITY');
  const { impersonatedUser } = useStore.getState();
  const { impersonateUser } = useStore((state) => state);
  const { t } = useTranslation();
  const navigate = useNavigate();

  let barConfig;
  if (isAdmin) {
    barConfig = adminSidebarConfig;
  } else if (isApi) {
    barConfig = apiSidebarConfig;
  } else {
    barConfig = isAuthority ? authoritySidebarConfig : sidebarConfig;
  }
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function onUnimpersonateClick() {
    impersonateUser('').then(() => {
      navigate(PATH_ADMIN.users);
    });
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <AccountStyle>
        <Link underline="none" component={RouterLink} to="#">
          <Avatar src={user.photoURL} alt="photoURL" />
        </Link>
        <div align="center">
          <Typography variant="h5">
            {user.firstName} {user.lastName}{' '}
          </Typography>
          {impersonatedUser && (
            <div>
              <Button onClick={onUnimpersonateClick}>{t('unimpersonate')}</Button>
            </div>
          )}
          {user.organization && (
            <Typography mt={0} key={user.organization.id} variant="caption">
              {user.organization.title}
            </Typography>
          )}
        </div>
      </AccountStyle>
      <NavSection navConfig={barConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
