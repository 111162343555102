import { PersistGate } from 'zustand-persist';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import useStore from './store/useStore';
import ThemeLocalization from './components/ThemeLocalization';
import NotistackProvider from './components/NotistackProvider';
import './locales/i18n';
import ConfirmProvider from './providers/ConfirmProvider';
// ----------------------------------------------------------------------

export default function App() {
  useStore();

  return (
    <PersistGate>
      <ThemeConfig>
        <ThemeLocalization>
          <ConfirmProvider>
            <NotistackProvider>
              <ScrollToTop />
              <Router />
            </NotistackProvider>
          </ConfirmProvider>
        </ThemeLocalization>
      </ThemeConfig>
    </PersistGate>
  );
}
