import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import useStore from '../../../store/useStore';
// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const { t } = useTranslation();
  const passwordReset = useStore((state) => state.passwordReset);
  const resetting = useStore((state) => state.resetting);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('email_invalid')).required(t('email_required'))
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      passwordReset(values.email);
    }
  });

  if (!formik.isSubmitting && resetting) formik.setSubmitting(true);
  if (formik.isSubmitting && !resetting) formik.setSubmitting(false);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('email_adress')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('reset')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
