import i18n from 'i18next';
import getAxiosConnection from '../utils/axios';

const initialState = {
  reports: [],
  removingReport: false,
  loadingReport: false,
  loadingReportByYear: false,
  loadingReports: false,
  savingReport: false
};

const reportSlice = (set, get) => ({
  ...initialState,
  getReports: async (ship) => {
    set({ loadingReports: true });
    const { language } = i18n;
    return getAxiosConnection()
      .get(`ship/${ship}/reports?language=${language}`)
      .then((res) => {
        set({ reports: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingReports: false });
      });
  },
  getReport: async (id) => {
    set({ loadingReport: true });
    const { language } = i18n;
    return getAxiosConnection()
      .get(`report/${id}?language=${language}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingReport: false });
      });
  },
  getReportByYear: async (shipId, year) => {
    set({ loadingReportByYear: true });
    return getAxiosConnection()
      .get(`ship/${shipId}/reportByYear?year=${year}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingReportByYear: false });
      });
  },
  createReport: async (report) => {
    set({ savingReport: true });
    return getAxiosConnection()
      .post(`ship/${report.ship}/report`, { ...report })
      .then((res) => {
        get().getReports();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingReport: false });
      });
  },
  removeReport: async (reportId) => {
    set({ removingReport: true });
    getAxiosConnection()
      .delete(`report/${reportId}`)
      .then(() => {
        const oldReports = get().reports;
        const reports = oldReports.filter((report) => report.id !== reportId);
        set({ reports });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ removingReport: false });
      });
  }
});

export default reportSlice;
