import PropTypes from 'prop-types';
import { CardContent, Modal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';
import { DetailCard, LabelStyle } from '../../../layouts/styles';
import HoursForm from './HoursForm';
import { fDate } from '../../../utils/formatTime';

const ModalDiv = styled('div')({
  position: 'fixed',
  width: '80%',
  minWidth: '200px',
  height: '90%',
  minHeight: '300px',
  overflow: 'auto',
  backgroundColor: 'rgba(0 , 0, 0, 0)',
  boxShadow: '0',
  padding: '0'
});

const ModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
};
HoursModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  engine: PropTypes.object,
  reading: PropTypes.object
};

export default function HoursModal({ isOpen, close, engine, reading }) {
  const { t } = useTranslation();
  const addedDateTime = reading ? reading.added : null;
  let dateFrom = null;
  let dateUntil = null;
  if (addedDateTime !== null) {
    dateFrom = new Date(addedDateTime);
    dateUntil = new Date(addedDateTime);
    dateUntil.setMonth(dateFrom.getMonth() + 3);
  }

  return (
    <Modal open={isOpen} onClose={() => close()}>
      <ModalDiv style={ModalStyle()}>
        <DetailCard sx={{ p: 3 }}>
          <CardContent>
            <LabelStyle>{t('engine.hours_period', { from: fDate(dateFrom) })}</LabelStyle>
            <HoursForm
              submitCallback={() => close()}
              onCancel={() => close()}
              engine={engine}
              reading={reading}
            />
          </CardContent>
        </DetailCard>
      </ModalDiv>
    </Modal>
  );
}
