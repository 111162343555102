import { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import useStore from '../../../../store/useStore';
import FuelConsumptionForm from '../../fuelConsumption/FuelConsumptionForm';
import { fDate } from '../../../../utils/formatTime';

FuelUsage.propTypes = {
  jumpToStep: PropTypes.func
};

export default function FuelUsage({ jumpToStep }) {
  const { t } = useTranslation();
  const { wizardShip } = useStore((state) => state);
  const year = new Date().getFullYear() - 1;
  const [fuelConsumption, setFuelConsumption] = useState(null);
  const { getFuelConsumption, loadingFuelConsumption } = useStore((state) => state);

  useEffect(() => {
    getFuelConsumption(wizardShip.id, year).then((res) => {
      if (res) {
        setFuelConsumption(res);
      }
    });
  }, [wizardShip, getFuelConsumption, year]);

  const onSave = (fuelConsumption) => {
    setFuelConsumption(fuelConsumption);
    jumpToStep(4);
  };

  if (loadingFuelConsumption) {
    return (
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          <Typography variant="body2">{t('fuel_consumption.loading')}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} padding={3}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {t('fuel_consumption.fill_period', {
            from: fDate(new Date(year, 0, 1)),
            to: fDate(new Date(year, 11, 31))
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FuelConsumptionForm
          submitCallback={(fc) => onSave(fc)}
          onCancel={() => jumpToStep(2)}
          date={new Date(year, 0, 2).toISOString()}
          ship={wizardShip}
          cancelButtonLabel="previous"
          submitButtonLabel="next"
          consumption={fuelConsumption}
        />
      </Grid>
    </Grid>
  );
}
