import { useEffect, useState } from 'react';
import { Button, Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import useStore from '../../../../store/useStore';
import EngineTable from '../../ship/engine/EngineTable';
import EngineForm from '../../ship/engine/EngineForm';

const EngineInfo = ({ jumpToStep }) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('overview');
  const { wizardShip } = useStore((state) => state);
  const { getEngines, loadingEngines } = useStore((state) => state);
  const { getEngine, loadingEngine } = useStore((state) => state);
  const [engines, setEngines] = useState([]);
  const [engine, setEngine] = useState(null);

  useEffect(() => {
    if (wizardShip) {
      getEngines(wizardShip.id).then((res) => {
        if (res.length === 0) setViewMode('create');
        setEngines(res);
      });
    }
  }, [wizardShip, getEngines]);

  const onNewClick = () => {
    setViewMode('create');
  };

  const onEngineCreated = (engine) => {
    setEngines([...engines, engine]);
    setEngine(engine);
    setViewMode('overview');
  };

  const onCancel = () => {
    setViewMode('overview');
  };

  const onEngineEdited = (engine) => {
    const cleanEngines = engines.filter((e) => e.id !== engine.id);
    setEngines([...cleanEngines, engine]);
    setViewMode('overview');
  };

  const editEngine = (id) => {
    getEngine(id).then((engine) => {
      setEngine(engine);
      setViewMode('edit');
    });
  };

  if (loadingEngine || loadingEngines) {
    return (
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          <Typography variant="body2">
            {loadingEngines ? t('engine.loading_engines') : t('engine.loading_details')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (viewMode === 'create') {
    return (
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t('new_engine')}</Typography>
          <Typography variant="body2">{t('engine.list_description')}</Typography>
          <EngineForm
            ship={wizardShip}
            onCancel={onCancel}
            submitCallback={(s) => onEngineCreated(s)}
          />
        </Grid>
      </Grid>
    );
  }

  if (viewMode === 'edit') {
    return (
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t('new_engine')}</Typography>
          <EngineForm
            engine={engine}
            ship={wizardShip}
            onCancel={onCancel}
            submitCallback={(s) => onEngineEdited(s)}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} padding={3}>
      <Grid item xs={12}>
        <EngineTable engines={engines} editEngine={(id) => editEngine(id)} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            type="submit"
            variant="contained"
            onClick={() => onNewClick()}
            disabled={!wizardShip}
          >
            {t('create_engine')}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
          <Button type="submit" variant="contained" color="secondary" onClick={() => jumpToStep(1)}>
            {t('previous')}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={engines.length === 0}
            onClick={() => jumpToStep(3)}
          >
            {t('next')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

EngineInfo.propTypes = {
  jumpToStep: PropTypes.func
};

export default EngineInfo;
