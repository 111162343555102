import getAxiosConnection from '../utils/axios';

const initialState = {
  organization: {},
  acceptingInvitation: false,
  invitingUser: false,
  editingUser: false,
  loadingInvitation: false,
  loadingOrganization: false,
  savingOrganization: false
};

const organizationSlice = (set, get) => ({
  ...initialState,
  getOrganization: async (id) => {
    set({ loadingOrganization: true });
    return getAxiosConnection()
      .get(`organization/${id}`)
      .then((res) => {
        set({ organization: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingOrganization: false });
      });
  },
  activateOrganization: async (id) => {
    set({ savingOrganization: true });
    return getAxiosConnection()
      .get(`organization/activate/${id}`)
      .then((res) => {
        set({ organization: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingOrganization: false });
      });
  },
  inviteUser: async (organizationId, email) => {
    set({ invitingUser: true });
    return getAxiosConnection()
      .get(`organization/${organizationId}/invite/${email}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ invitingUser: false });
      });
  },
  removeUser: async (memberId) => {
    set({ removingUser: true });
    return getAxiosConnection()
      .delete(`organization/member/${memberId}`)
      .then((res) => {
        set({ organization: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ removingUser: false });
      });
  },
  demoteUser: async (memberId) => {
    set({ editingUser: true });
    return getAxiosConnection()
      .get(`organization/member/demote/${memberId}`)
      .then((res) => {
        set({ organization: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ editingUser: false });
      });
  },
  promoteUser: async (memberId) => {
    set({ editingUser: true });
    return getAxiosConnection()
      .get(`organization/member/promote/${memberId}`)
      .then((res) => {
        set({ organization: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ editingUser: false });
      });
  },
  updateOrganization: async (organization) => {
    set({ savingOrganization: true });
    return getAxiosConnection()
      .post(`organization/${organization.id}`, { ...organization })
      .then((res) => {
        set({ organization: res.data });
        get().getUserInfo();
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingOrganization: false });
      });
  },
  getInvitation: async (uuid) => {
    set({ loadingInvitation: true });
    return getAxiosConnection()
      .get(`organization/invite/${uuid}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingInvitation: false });
      });
  },
  acceptInvitation: async (uuid) => {
    set({ acceptingInvitation: true });
    return getAxiosConnection()
      .get(`organization/accept/${uuid}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ acceptingInvitation: false });
      });
  },
  isUserShipAdmin: async (shipId) =>
    getAxiosConnection()
      .get(`organization/ship/${shipId}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      }),
  getShipOrganizationMembers: async (shipId) =>
    getAxiosConnection()
      .get(`organization/ship/membersByShip/${shipId}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      }),
  addShipEditor: async (shipId, userId) =>
    getAxiosConnection()
      .get(`ship/${shipId}/editor/add/${userId}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      }),
  removeShipEditor: async (shipId, userId) =>
    getAxiosConnection()
      .get(`ship/${shipId}/editor/remove/${userId}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      })
});

export default organizationSlice;
