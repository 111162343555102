import { useEffect } from 'react';
import { motion } from 'framer-motion';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Typography, Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import useStore from '../store/useStore';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Logout() {
  const navigate = useNavigate();
  const authenticated = useStore((state) => state.authenticated);
  const logout = useStore((state) => state.logout);

  useEffect(() => {
    if (!authenticated) navigate('/login', { replace: true });
  }, [authenticated, navigate]);

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <RootStyle title="Log out">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Logging out
              </Typography>
            </motion.div>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
