import axios from 'axios';
import useStore from '../store/useStore';

export default function getAxiosInstance($contentType = 'application/json', $responseType = '') {
  const { impersonatedUser } = useStore.getState();
  const parameters = impersonatedUser ? { _switch_user: impersonatedUser } : {};

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    params: parameters
  });

  const { user } = useStore.getState();

  const { token, refreshToken } = user;
  instance.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers['Content-Type'] = $contentType;
      if ($responseType) config.responseType = $responseType;
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config;
      if (token && refreshToken && originalConfig.url !== '/token/refresh' && error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await instance.post('/token/refresh', {
              refresh_token: refreshToken
            });
            user.token = rs.data.token;
            user.refresh_token = rs.data.refresh_token;
            useStore.setState({ user });
            // todo original config replace bearer key
            return instance(originalConfig);
          } catch (_error) {
            const { logout } = useStore.getState();
            logout();
          }
        }
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );
  return instance;
}
