import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Paper, Box, Typography, Stack } from '@material-ui/core';
// utils
import pinFill from '@iconify/icons-eva/pin-fill';
import { isString } from 'lodash';
import { fData } from '../../utils/formatNumber';
//
import { UploadIllustration } from '../../_assets';
import { IconStyle } from '../../layouts/styles';
// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer'
  },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
  subtext: PropTypes.string
};

export default function UploadSingleFile({ error, file, sx, subtext, ...other }) {
  const { t } = useTranslation();
  if (typeof file === 'string' || file instanceof String) file = null;

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  function getFileName(file) {
    if (file instanceof File) return isString(file) ? file : file.name;
    return file.originalName;
  }

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ width: '100%', p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            {t('upload.drop')}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {subtext}
          </Typography>

          {file && (
            <Stack direction="row">
              <IconStyle icon={pinFill} />
              <Typography variant="body2">
                <b>{getFileName(file)}</b>
              </Typography>
            </Stack>
          )}
        </Box>
      </DropZoneStyle>
      {fileRejections.length > 0 && <ShowRejectionItems />}
    </Box>
  );
}
