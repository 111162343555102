import getAxiosConnection from '../utils/axios';

const initialState = {
  keys: [],
  loadingApiKeys: false,
  savingApiKey: false,
  deletingApiKey: false,
  apiKey: null
};

const apiKeySlice = (set, get) => ({
  ...initialState,
  getApiKeys: async () => {
    set({ loadingApiKeys: true });
    return getAxiosConnection()
      .get(`api-keys`)
      .then((res) => {
        set({ keys: res.data });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingApiKeys: false });
      });
  },
  createApiKey: async (apiKey) => {
    set({ savingApiKey: true });
    return getAxiosConnection()
      .post(`api-keys`, { ...apiKey })
      .then((res) => {
        set({ apiKey: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingApiKey: false });
      });
  },
  deleteApiKey: (id) => {
    set({ deletingShip: true });
    getAxiosConnection()
      .delete(`api-keys/${id}`)
      .then(() => {
        const keys = get().keys.filter((key) => key.id !== id);
        set({ keys });
        return id;
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ deletingApiKey: false });
      });
  }
});

export default apiKeySlice;
