import { Icon } from '@iconify/react';
import settings from '@iconify/icons-eva/settings-2-fill';
import { PATH_API } from '../../routes/paths';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const apiSidebarConfig = [
  {
    title: 'api_settings',
    path: PATH_API.api_settings,
    icon: getIcon(settings)
  }
];
export default apiSidebarConfig;
