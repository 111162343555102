import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Scrollbar from '../../../Scrollbar';
import EngineListHead from './EngineListHead';
import { fDate } from '../../../../utils/formatTime';
import useDownloadList from '../../../../hooks/useDownloadList';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  if (array.size === 0) return array;
  array.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) return order;
    return a - b;
  });

  return array;
}

EngineHourTable.propTypes = {
  engine: PropTypes.object
};

export default function EngineHourTable({ engine }) {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('title');
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { downloadCell } = useDownloadList();

  const TABLE_HEAD = [
    { id: 'reading', label: t('hours.hour'), alignRight: false },
    { id: 'added', label: t('hours.added'), alignRight: false },
    {}
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const data = applySortFilter(engine.hourReadings, getComparator(order, orderBy));
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - engine.hourReadings.length) : 0;

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <EngineListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id, added, proof, reading } = row;
                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell>{reading}</TableCell>
                    <TableCell>{fDate(added)}</TableCell>
                    {downloadCell(proof.fileName)}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={engine.hourReadings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
