import { styled } from '@material-ui/core/styles';
import { Card, TableCell, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';

/** Cards */
export const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  textAlign: 'left'
}));

/** divs */
export const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4)
}));

/** Typographies */
export const Description = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  paddingBottom: '1rem'
}));

export const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(4),
  textAlign: 'left'
}));

export const SubLabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  fontWeight: 400,
  marginTop: '-1rem',
  fontStyle: 'oblique',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(4),
  textAlign: 'left'
}));

/** Icons */
export const IconStyle = styled(Icon)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2)
}));

export const DownloadCell = styled(TableCell)({
  align: 'right',
  fontSize: '1.4rem',
  '&:hover': {
    color: 'green',
    cursor: 'pointer'
  }
});
