import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  Button,
  CardContent,
  TableHead
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import useStore from '../../../store/useStore';
import Scrollbar from '../../Scrollbar';
import FuelConsumptionModal from './FuelConsumptionModal';
import { fDate } from '../../../utils/formatTime';

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  textAlign: 'center'
}));

export default function FuelConsumptionList() {
  const { t } = useTranslation();
  const loading = useStore((state) => state.loadingQuarterlyFuelConsumptions);
  const fuelConsumptions = useStore((state) => state.quarterlyFuelConsumptions);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const onAddClick = (request) => {
    setCurrentRequest(request);
    setModalIsOpen(true);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fuelConsumptions.length) : 0;
  const isMeasurementsEmpty = fuelConsumptions.length === 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CardHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4)
  }));

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary
  }));

  return (
    <DetailCard sx={{ p: 3 }}>
      <CardHeader>
        <LabelStyle>{t('fuel_consumption.required_consumption')}</LabelStyle>
      </CardHeader>
      <CardContent sx={{ p: 0 }}>
        <Typography sx={{ pb: 2 }} variant="body2" align="left">
          {t('fuel_consumption.required_consumption_explanation')}
        </Typography>
        <FuelConsumptionModal
          close={() => setModalIsOpen(false)}
          isOpen={modalIsOpen}
          request={currentRequest}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('ship.title')}</TableCell>
                  <TableCell>{t('fuel_consumption.requested_quarter')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              {!loading && (
                <TableBody>
                  {fuelConsumptions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { ship, title, beginDate, endDate } = row;
                      return (
                        <TableRow hover key={`${ship.id}-${title}`} tabIndex={-1}>
                          <TableCell component="th" scope="row">
                            <Typography
                              to={`/client/ship/${ship.id}`}
                              component={RouterLink}
                              variant="subtitle2"
                              noWrap
                            >
                              {ship.title}
                            </Typography>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography variant="subtitle2" noWrap>
                              {title} ({fDate(beginDate)} - {fDate(endDate)})
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Button variant="contained" onClick={() => onAddClick(row)}>
                              <Icon icon={plusFill} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                </TableBody>
              )}
              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={3} sx={{ py: 3 }}>
                      <Paper>
                        <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isMeasurementsEmpty && !loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={3} sx={{ py: 3 }}>
                      <Paper>
                        <Typography gutterBottom align="center" variant="subtitle1">
                          {t('label.no_labels')}
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={fuelConsumptions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </DetailCard>
  );
}
