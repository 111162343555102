import PropTypes from 'prop-types';
import { Typography, Stack, Grid, CardHeader } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { DetailCard } from '../../../../../../layouts/styles';

ShipInfo.propTypes = {
  ship: PropTypes.object,
  loading: PropTypes.bool
};

export default function ShipInfo({ ship, loading }) {
  const { t } = useTranslation();

  if (loading) {
    return (
      <DetailCard>
        <CardHeader title={t('request.ship_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
            <Typography variant="body2">{t('ship.loading_details')}</Typography>
          </Grid>
        </Grid>
      </DetailCard>
    );
  }

  if (!ship) {
    return (
      <DetailCard>
        <CardHeader title={t('request.ship_info')} />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100px' }}
        >
          <Grid item xs={3}>
            <Typography variant="body2">{t('no_data')}</Typography>
          </Grid>
        </Grid>
      </DetailCard>
    );
  }

  return (
    <DetailCard>
      <CardHeader title={t('request.ship_info')} />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.title')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.title}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.type')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.type.description}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.ares')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.aresRegistrationNumber}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.eni')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.eni}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.length')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.length} m</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.width')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.width} m</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.payloadTonne')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.payloadTonne}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.payloadTEU')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.payloadTEU}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.payloadQubicMeters')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.payloadQubicMeters}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.payloadPassengers')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.payloadPasengers}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{t('ship.year')}:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{ship.year}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </DetailCard>
  );
}
