import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Toolbar,
  Button
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import moment from 'moment';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import useStore from '../../../store/useStore';
import Scrollbar from '../../Scrollbar';
import ApiKeysListHead from './ApiKeysListHead';
import ApiKeyMoreMenu from './ApiKeyMoreMenu';
import { fDate } from '../../../utils/formatTime';
import { CardHeader, LabelStyle } from '../../../layouts/styles';
import ApiKeyForm from './ApiKeyForm';
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  if (array.size === 0) return array;
  array.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) return order;
    return a - b;
  });

  return array;
}

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  textAlign: 'center'
}));

const RootStyle = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'flex-end'
});

const RightBox = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export default function ApiKeysList() {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('overview');
  const loading = useStore((state) => state.loadingApiKeys);
  const getApiKeys = useStore((state) => state.getApiKeys);
  const keys = useStore((state) => state.keys);

  useEffect(() => {
    getApiKeys();
  }, [getApiKeys]);

  const [orderBy, setOrderBy] = useState('encrypted-api-key');
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const TABLE_HEAD = [
    { id: 'name', label: t('name'), alignRight: false },
    { id: 'createdDate', label: t('api.created_date'), alignRight: false },
    { id: '' }
  ];

  const handleKeySort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - keys.length) : 0;
  const isKeyEmpty = keys.length === 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filteredKeys = applySortFilter(keys, getComparator(order, orderBy));

  const onCancel = () => {
    setViewMode('overview');
    getApiKeys();
  };

  if (viewMode === 'create') {
    return (
      <DetailCard sx={{ p: 3 }}>
        <CardHeader>
          <LabelStyle>{t('api.new_api_key')}</LabelStyle>
        </CardHeader>
        <ApiKeyForm onCancel={onCancel} />
      </DetailCard>
    );
  }
  return (
    <DetailCard sx={{ p: 3 }}>
      <RootStyle>
        <Tooltip title={t('refresh')}>
          <RightBox>
            {loading && (
              <Typography
                align="right"
                variant="body2"
                sx={{ fontStyle: 'italic', color: 'text.secondary' }}
              >
                {t('refreshing')}...
              </Typography>
            )}
            <Button
              sx={{ marginRight: '5px' }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => setViewMode('create')}
            >
              {t('api.add_key')}
            </Button>
            <IconButton onClick={() => getApiKeys()}>
              <Icon icon={refreshOutline} />
            </IconButton>
          </RightBox>
        </Tooltip>
      </RootStyle>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ApiKeysListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleKeySort}
            />
            <TableBody>
              {filteredKeys
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, name, createdDate } = row;
                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell>{name}</TableCell>
                      <TableCell>{fDate(moment(createdDate))}</TableCell>
                      <TableCell align="right">
                        <ApiKeyMoreMenu id={id} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isKeyEmpty && !loading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper>
                      <Typography gutterBottom align="center" variant="subtitle1">
                        {t('api.no_keys')}
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={keys.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DetailCard>
  );
}
